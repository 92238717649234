
import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import './HospitalityIndustriesPage.css'; // Import custom CSS


const HospitalityIndustriesPage = () => {
 
      return (
        <>
          {/* Full-Width Image Section */}
          <div className="full-width-image">
            <Image
              src="images/industry.png" // Replace with your image URL
              alt="Hospitality Industry"
               className="img-fluid"
            />
          </div>
    
    
          <Container  className="my-4 ml-3 pl-4">
            <h2 className="text-center mb-4 h2clas">Hospitality Industry</h2>
            <p>The hospitality industry is a broad and dynamic sector that plays a vital role in the global economy, encompassing a wide range of services aimed at providing comfort, entertainment, and satisfaction to customers. It includes various segments such as hotels, restaurants, event planning, travel and tourism, and more, each contributing to the overall guest experience. Here’s a comprehensive overview of the hospitality industry:


</p>
            <Row className="gy-4">
              {/* Section 1 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">1. Overview of the Hospitality Industry</Card.Title>
                    
                      <p><b>Definition:</b> The hospitality industry is defined by businesses and services that cater to guests' needs, offering food, lodging, entertainment, and other services that enhance their experience.</p>
                      <p><b>Scope:</b> It is a global industry that includes everything from luxury hotels and fine dining restaurants to budget accommodations, fast food chains, cruise lines, and tourism agencies.</p>
                    
                  </Card.Body>
                </Card>
              </Col>
    
    
              {/* Section 2 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">2. Key Sectors within the Hospitality Industry</Card.Title>
                    
                      <p><b>Hotels and Lodging:</b> This sector includes a wide range of accommodations, from luxury resorts and boutique hotels to motels and hostels. Hotels provide not only lodging but also services such as food and beverage, recreation, and event management.</p>
                      <p><b>Food and Beverage:</b> Restaurants, cafes, bars, and catering services fall under this category. It is one of the largest sectors within hospitality, with a focus on providing dining experiences that range from fast food to gourmet cuisine.</p>
                      <p><b>Travel and Tourism:</b> This sector encompasses services related to travel, including airlines, travel agencies, tour operators, and tourism boards. It involves the organization and facilitation of travel experiences, both domestically and internationally.</p>
                      <p><b>Event Planning and Management:</b> This includes the organization of events such as weddings, conferences, exhibitions, and concerts. Event planners coordinate every aspect of an event to ensure it runs smoothly and meets the clients' expectations.</p>
                      <p><b>Recreation and Entertainment:</b> Theme parks, casinos, sports venues, and other entertainment facilities are part of this sector. They provide leisure activities that attract tourists and local visitors alike.</p>
                      <p><b>Cruise Lines:</b> The cruise industry is a specialized sector that combines elements of travel, lodging, dining, and entertainment, offering guests an all-inclusive vacation experience on the water.</p>
                    
                  </Card.Body>
                </Card>
              </Col>
    
    
              {/* Section 3 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">3. Economic Impact</Card.Title>
                
                      <p><b>Global Contribution:</b> The hospitality industry is a significant contributor to the global economy, generating trillions of dollars in revenue and employing millions of people worldwide. It is one of the largest employers, providing a wide range of job opportunities at various skill levels.</p>
                      <p><b>Tourism Dependency:</b> Many countries rely heavily on tourism as a key driver of their economies. The hospitality industry is closely linked to tourism, as it provides the necessary infrastructure and services to accommodate tourists.</p>
                      <p><b>Investment and Growth:</b> The industry attracts substantial investment in new hotels, restaurants, and entertainment facilities, particularly in emerging markets and tourist hotspots. This continuous investment drives growth and innovation within the sector.</p>
                    
                  </Card.Body>
                </Card>
              </Col>
    
    
              {/* Section 4 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">4. Trends Shaping the Hospitality Industry</Card.Title>
                    
                      <p><b>Sustainability:</b> There is a growing emphasis on sustainable practices within the industry, with businesses adopting eco-friendly initiatives such as reducing energy consumption, minimizing waste, and sourcing local and organic products.</p>
                      <p><b>Technology Integration:</b> The hospitality industry is increasingly embracing technology to enhance the guest experience. This includes the use of mobile apps for booking and check-in, artificial intelligence for personalized service, and virtual reality for immersive marketing.</p>
                      <p><b>Personalization:</b> Modern consumers expect personalized experiences tailored to their preferences. Hospitality businesses are using data analytics and customer feedback to customize services, from room preferences in hotels to tailored dining experiences in restaurants.</p>
                      <p><b>Health and Wellness:</b> The demand for health and wellness services is on the rise, with hotels and resorts offering wellness programs, spa services, and healthy dining options to cater to this growing market segment.</p>
                      <p><b>Experience Economy:</b> Travelers today seek unique and authentic experiences. The hospitality industry is responding by offering experiential travel packages, local cultural immersion, and adventure tourism.</p>
                    
                  </Card.Body>
                </Card>
              </Col>
    
    
              {/* Section 5 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">5. Challenges in the Hospitality Industry</Card.Title>
                
                      <p><b>Economic Fluctuations:</b> The industry is sensitive to economic downturns, as discretionary spending on travel, dining, and entertainment often decreases during periods of economic uncertainty.</p>
                      <p><b>Labor Shortages:</b> The industry often faces challenges in recruiting and retaining skilled workers, particularly in regions with high demand for hospitality services.</p>
                      <p><b>Changing Consumer Preferences:</b> The hospitality industry must continuously adapt to changing consumer preferences and expectations, particularly in areas such as sustainability, health, and technology.</p>
                      <p><b>Regulatory Compliance:</b> Hospitality businesses must navigate a complex web of regulations, including health and safety standards, labor laws, and environmental regulations, which can vary significantly by region.</p>
                    
                  </Card.Body>
                </Card>
              </Col>
    
    
              {/* Section 6 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">6. The Future of the Hospitality Industry</Card.Title>
                    
                      <p><b>Innovation and Adaptation:</b> The future of the hospitality industry will be shaped by continued innovation, with businesses adapting to new technologies, sustainable practices, and evolving customer expectations.</p>
                      <p><b>Global Expansion:</b> As emerging markets continue to grow, there will be increased opportunities for hospitality businesses to expand globally, particularly in regions with growing middle-class populations and increased travel.</p>
                      <p><b>Focus on Safety and Hygiene:</b> In the wake of the COVID-19 pandemic, there is a heightened focus on safety and hygiene within the industry. Hospitality businesses will need to maintain rigorous standards to reassure customers and ensure their well-being.</p>
                
                  </Card.Body>
                </Card>
              </Col>
    
    
              {/* Section 7 */}
              <Col md={9}>
                <Card className="border-box">
                  <Card.Body className='ulclas'>
                    <Card.Title className="sub-heading-bg">7. Career Opportunities</Card.Title>
                    
                      <p><b>Diverse Roles:</b> The hospitality industry offers a wide range of career opportunities, from front-line roles in guest services and food and beverage to management positions in hotels, restaurants, and event planning.</p>
                      <p><b>Global Mobility:</b> Careers in hospitality can take you anywhere in the world, with opportunities to work in different countries and experience diverse cultures.</p>
                      <p><b>Career Progression:</b> The industry is known for offering rapid career progression, with many opportunities for professional growth and development.</p>
                
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>    


  );
};


export default HospitalityIndustriesPage;




