import React from 'react';
import { Card, Container } from 'react-bootstrap';
import './Coursesss.css';
import { Link } from 'react-router-dom';

function CousessLanguage() {
  const cards = [
    { img: 'images/englishs.png', title: 'Hospitality', text: 'Learn about the hospitality industry and its growth potential.', link: "/courses/english" },
    { img: 'images/frenchs.png', title: 'Soft Skills', text: 'Develop crucial soft skills that will enhance your career.', link: "/courses/french" },
    { img: 'images/germans.png', title: 'Languages', text: 'Master new languages and expand your global communication skills.', link: "/courses/german" },
    { img: 'images/arabics.png', title: 'Technology', text: 'Stay updated with the latest advancements in technology.', link: "/courses/arabic" },
    { img: 'images/spanishs.png', title: 'Health & Wellness', text: 'Learn how to maintain a healthy and balanced lifestyle.', link: "/courses/spanish" },
  ];

  return (
    <Container fluid={true} className='p-0 m-0' style={{ padding: '0px', marginTop: '17px' }}>
      <h2 className='h2class'>Languages</h2>

      {/* Horizontal Scrollable Container */}
      <div className="scroll-container">
        {cards.map((card, index) => (
          <Link to={card.link} key={index} className="card-link">
            <Card className='cardClass'>
              <Card.Img variant="top" src={card.img} 
                style={{ height: '100%', objectFit: 'cover', padding: '0' }}/>
            </Card>
          </Link>
        ))}
      </div>
    </Container>
  );
}

export default CousessLanguage;
