import React, { useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import './CousessLanguage.css';
import { Link } from 'react-router-dom';


function CoursesHospitality() {
  // State to track whether scrolling is paused
  const [isScrolling, setIsScrolling] = useState(true);



  const cards = [
    { img: 'images/financialss.png', title: 'financials', text: 'Learn about the financial and its growth potential.', link: "/courses/masteringhotelfinancials" },
    { img: 'images/foodandbeverages.png', title: 'foodandbeverage', text: ' food and beverages that will enhance your career.', link: "/courses/foodandbeveragemanagementtraining" },
    { img: 'images/kitchenoperations.png', title: 'kitchenoperation', text: 'Master in kitchen operationsn and expand your global communication skills.', link: "/courses/kitchenoperationstraining" },
    { img: 'images/management.png', title: 'management', text: 'Stay updated with the latest advancements in technology.', link: "/courses/foodindustrymanagementandoperations" },
    { img: 'images/frontoffices.png', title: 'frontoffice', text: 'Learn how to maintain a healthy and balanced front office managment.', link: "/courses/frontofficemanagementtraining" },
    { img: 'images/roomservices.png', title: 'roomservice', text: 'Unleash your creativity with our comprehensive arts programs.', link: "/courses/roomserviceskillstraining" },
    { img: 'images/marketings.png', title: 'Marketing', text: 'Learn the latest marketing strategies and digital marketing skills.', link: "/courses/hotelmarketingstrategiestraining" },
    { img: 'images/restaurantmanagements.png', title: 'restaurantmanagement', text: 'Get  a successful restaurant managements traning.', link: "/courses/restaurantmanagementtraining" },
  ];


  // Function to handle mouse entering (pause scrolling)
  const handleMouseEnter = () => setIsScrolling(false);


  // Function to handle mouse leaving (resume scrolling)
  const handleMouseLeave = () => setIsScrolling(true);


  return (
    <Container fluid={true} className="m-0 p-0" style={{marginTop: '17px' }}>
      <h2 className="h2class" style={{paddingLeft:'50px'}}>Hospitality Courses</h2>
      <div
        className={`scrolling-container ${isScrolling ? 'scrolling' : ''}`} // Control scrolling class
        onMouseEnter={handleMouseEnter} // Stop scrolling on hover
        onMouseLeave={handleMouseLeave} // Resume scrolling when not hovered
      >
        <div className="scrolling-row">
          {cards.map((card, index) => (
            <div key={index} className="card-wrapper">
              <Link to={card.link} className="card-link"> {/* Make card clickable */}
                <Card className="cardClass">
                  <Card.Img variant="top" src={card.img} alt={card.title} />
                 
                </Card>
              </Link>
            </div>
          ))}
          {/* Clone the cards for seamless scrolling */}
          {cards.map((card, index) => (
            <div key={`clone-${index}`} className="card-wrapper">
              <Link to={card.link} className="card-link"> {/* Make cloned card clickable */}
                <Card className="cardClass">
                  <Card.Img variant="top" src={card.img} alt={card.title} />
                 
                </Card>
              </Link>
            </div>
          ))}
        </div>

      </div>
    </Container>
  );
}


export default CoursesHospitality;