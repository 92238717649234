import React, { useRef, useEffect } from 'react'

function PersonalityDevelopmentandSoftSkillsTraining({ selectedSection }) {


    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("PersonalityDevelopmentandSoftSkillsTraining");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Personality Development and Soft Skills Training</h2>
                <p>This course is designed to help participants
                    enhance their personality traits and soft
                    skills, focusing on self-awareness,
                    communication, leadership, and emotional
                    intelligence. It aims to equip learners with the
                    tools to improve their personal and
                    professional lives by building confidence,
                    interpersonal skills, and a positive mindset.


                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 8 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>2 days per week,</p>
                <p>2 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <><h4>Module 1</h4>
                    <h5><strong>Understanding Personality</strong></h5>
                    <ul>
                        <li>Introduction to Personality Development</li>
                        <li>Self-Awareness: Understanding Your Strengths and
                            Weaknesses</li>
                        <li>The Role of Attitude and Behavior in Personality</li>
                        <li>Setting Personal and Professional Goals</li>
                    </ul>
                </>

                <><h4>Module 2</h4>
                    <h5><strong>Building Self-Confidence and Positive Thinking</strong></h5>
                    <ul>
                        <li>Techniques for Boosting Self-Confidence</li>
                        <li>Overcoming Negative Thoughts and Self-Doubt</li>
                        <li>Developing a Positive Attitude and Growth Mindset</li>
                        <li>The Power of Affirmations and Visualization</li>
                    </ul>
                </>

                <><h4>Module 3</h4>
                    <h5><strong>Effective Communication Skills</strong></h5>
                    <ul>
                        <li>Verbal and Non-Verbal Communication</li>
                        <li>Active Listening and Empathy</li>
                        <li>Assertiveness Training: How to Express Yourself Clearly</li>
                        <li>Public Speaking and Presentation Skills</li>
                    </ul>
                </>

                <><h4>Module 4</h4>
                    <h5><strong>Emotional Intelligence and Stress Management</strong></h5>
                    <ul>
                        <li>Understanding Emotional Intelligence (EQ)</li>
                        <li>Techniques for Managing and Expressing Emotions</li>
                        <li>Building Resilience and Coping with Stress</li>
                        <li>Developing Empathy and Interpersonal Skills</li>
                    </ul>
                </>

                <><h4>Module 5</h4>
                    <h5><strong>Leadership and Team-Building</strong></h5>
                    <ul>
                        <li>Understanding Different Leadership Styles</li>
                        <li>Developing Leadership Qualities and Skills</li>
                        <li>Building and Leading Effective Teams</li>
                        <li>Conflict Resolution and Decision-Making Skills</li>
                    </ul>
                </>

                <><h4>Module 6</h4>
                    <h5><strong>Time Management and Goal Setting</strong></h5>
                    <ul>
                        <li>Prioritizing Tasks and Managing Time Effectively</li>
                        <li>Setting SMART Goals and Achieving Them</li>
                        <li>Overcoming Procrastination and Building Discipline</li>
                        <li>Balancing Personal and Professional Life</li>
                    </ul>
                </>

                <>
                    <h4>Module 7</h4>
                    <h5><strong>Grooming and Professional Etiquette</strong></h5>
                    <ul>
                        <li>Personal Grooming and Presentation</li>
                        <li>Business Etiquette and Professionalism</li>
                        <li>Networking Skills and Building Professional
                        Relationships</li>
                        <li>Social Media Etiquette and Online Presence</li>
                    </ul>
                </>
                
                <>
                    <h4>Module 8</h4>
                    <h5><strong>Continuous Personal Development</strong></h5>
                    <ul>
                        <li>Creating a Personal Development Plan</li>
                        <li>Lifelong Learning and Self-Improvement Strategies</li>
                        <li>Building and Maintaining Healthy Habits</li>
                        <li>Reflection and Continuous Growth</li>
                    </ul>
                </>
            
            </div>

        </>


    )
}

export default PersonalityDevelopmentandSoftSkillsTraining