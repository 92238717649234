// import React, { useState } from 'react';
// import { Form, Button, FloatingLabel, Container } from 'react-bootstrap'; 
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios'; // Axios for HTTP requests
// import './EnquiryForm.css'; 

// const BusinessEnquiryForm = () => {
//   const [validated, setValidated] = useState(false);
//   const [state, setState] = useState(''); // Selected State field
//   const [city, setCity] = useState(''); // Selected City field
//   const [formData, setFormData] = useState({
//     Name: '',          // Name field
//     Mobile_No: '',     // Mobile_No field
//     Email: '',         // Email field
//     Investment: '',    // Investment field
//     Message: ''        // Message field
//   });

//   // Object containing cities for each state
//   const stateCities = {
      
//       "Assam": [
//         "Baksa", "Barpeta", "Bongaigaon", "Dhubri", "Dibrugarh", "Guwahati", "Hojai/Lumding",
//         "Lakhimpur", "Nalbari", "Patshala", "Silchar", "Sivsagar", "Tezpur", "Jorhat"
//       ],
//       "West Bengal": [
//         "Alipurduar", "Asansol", "Bankura", "Barasat", "Basir hat", "Behrampur", "Burdawan",
//         "Cooch Behar", "Dankuni", "Darjeeling", "Haldia", "Kaleempung", "Krishnanagar",
//         "Malda", "Mecheda", "North 24 Pargana", "North Barrackpore", "Panchkura", "Purba Burdawan",
//         "Purba Medinipur", "Raignaj", "Ranaghat", "Siliguri", "Sonapur", "Chandan Nagar", "Chinsurah",
//         "Maynaguri", "Metli", "Sambalpur", "Durgapur", "Kharagpur", "Nadia"
//       ],
//       "Odisha": [
//         "Balangir", "Balasore", "Bargarh","Baripada", "Bhadrak", "Bhubaneswar","Brahmapur", "Cuttack", "Jeypore",
//         "Jharsuguda", "Puri", "Rourkela", "Sambalpur","Talcher"
//       ]
    
    
//   };

//   // Handle form input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   // Handle form submission
//   const handleSubmit = async (event) => {
//     event.preventDefault(); // Prevent form default behavior (reloading the page)
//     const form = event.currentTarget;

//     // Validate form fields
//     if (form.checkValidity() === false) {
//       event.stopPropagation();
//       setValidated(true);
//       return;
//     }

//     setValidated(true); // Set form as validated

//     // Prepare all the form data to send
//     const dataToSend = {
//       ...formData,      // Name, Mobile_No, Email, Investment, and Message
//       Select_State: state,  // Select_State
//       Select_City: city    // Select_City
//     };

//     try {
//       // Sending form data using axios
//       const response = await axios.post("https://api.ocupiiehospitalityacademy.com/api/enquiries", dataToSend);
      
//       if (response.status === 201) {
//         // Handle success response
//         alert('Form submitted successfully!');
        
//         // Reset form fields
//         setFormData({
//           Name: '',
//           Mobile_No: '',
//           Email: '',
//           Investment: '',
//           Message: ''
//         });
//         setState(''); // Reset the state field
//         setCity(''); // Reset the city field
//       } else {
//         // Handle non-200 responses
//         alert('Something went wrong. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error submitting the form:', error.response ? error.response.data : error.message);
//       alert('Failed to submit the form. Please try again later.');
//     }
//   };

//   // Update cities based on the selected state
//   const handleStateChange = (e) => {
//     setState(e.target.value);
//     setCity(''); // Reset city when state changes
//   };

//   return (
//     <Container className='p-0'>
//       <Form noValidate validated={validated} onSubmit={handleSubmit} className="enquiry-formff">
//         <h4>Business Enquiry Form</h4>

//         {/* Name Field */}
//         <Form.Group controlId="name" className="mb-1">
//           <FloatingLabel label="Full Name">
//             <Form.Control
//               type="text"
//               name="Name" // Match backend field name
//               value={formData.Name}
//               onChange={handleInputChange}
//               placeholder="Full Name"
//               maxLength={255} // Corresponds to the backend rule
//               required
//             />
//             <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* Mobile Number Field */}
//         <Form.Group controlId="mobile" className="mb-1">
//           <FloatingLabel label="Phone Number">
//             <Form.Control
//               type="tel"
//               name="Mobile_No" // Match backend field name
//               value={formData.Mobile_No}
//               onChange={handleInputChange}
//               placeholder="Phone Number"
//               pattern="[0-9]{10,12}" // Digits between 10-12
//               required
//             />
//             <Form.Control.Feedback type="invalid">Please provide a valid phone number (10-12 digits).</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* Email Field */}
//         <Form.Group controlId="email" className="mb-1">
//           <FloatingLabel label="Email Address">
//             <Form.Control
//               type="email"
//               name="Email" // Match backend field name
//               value={formData.Email}
//               onChange={handleInputChange}
//               placeholder="Email Address"
//               required
//             />
//             <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* Investment Field */}
//         <Form.Group controlId="investment" className="mb-1">
//           <FloatingLabel label="Min. Investment 5 Lakh">
//             <Form.Control
//               type="number"
//               name="Investment" // Match backend field name
//               value={formData.Investment}
//               onChange={handleInputChange}
//               placeholder="Investment Amount"
//               min="500000"
//               required
//             />
//             <Form.Control.Feedback type="invalid">Please provide a valid investment amount (min 5 lakh).</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* State Selection */}
//         <Form.Group controlId="state" className="mb-1">
//           <FloatingLabel label="Select State">
//             <Form.Select value={state} onChange={handleStateChange} required>
//               <option value="">Select State</option>
//               {Object.keys(stateCities).map((stateName) => (
//                 <option key={stateName} value={stateName}>
//                   {stateName}
//                 </option>
//               ))}
//             </Form.Select>
//             <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* City Selection */}
//         <Form.Group controlId="city" className="mb-1">
//           <FloatingLabel label="Select City">
//             <Form.Select value={city} onChange={(e) => setCity(e.target.value)} required disabled={!state}>
//               <option value="">Select City</option>
//               {state && stateCities[state].map((cityName) => (
//                 <option key={cityName} value={cityName}>
//                   {cityName}
//                 </option>
//               ))}
//             </Form.Select>
//             <Form.Control.Feedback type="invalid">Please select a city.</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* Message Field */}
//         <Form.Group controlId="message" className="mb-1">
//           <FloatingLabel label="Your Message">
//             <Form.Control
//               as="textarea"
//               name="Message" // Match backend field name
//               value={formData.Message}
//               onChange={handleInputChange}
//               placeholder="Your Message"
//               required
//             />
//             <Form.Control.Feedback type="invalid">Please provide a message.</Form.Control.Feedback>
//           </FloatingLabel>
//         </Form.Group>

//         {/* Submit Button */}
//         <div className="d-flex justify-content-end align-items-end">
//           <Button type="submit">Submit</Button>
//         </div>
//       </Form>
//     </Container>
//   );
// };

// export default BusinessEnquiryForm;


import React, { useState } from 'react';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Axios for HTTP requests
import './EnquiryForm.css'; 

const BusinessEnquiryForm = () => {
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState(''); // Selected State field
  const [city, setCity] = useState(''); // Selected City field
  const [formData, setFormData] = useState({
    Name: '',          // Name field
    Mobile_No: '',     // Mobile_No field
    Email: '',         // Email field
    Investment: '',    // Investment field
    Message: ''        // Message field
  });

  // Object containing cities for each state
  const stateCities = {
    "Assam": [
      "Baksa", "Barpeta", "Bongaigaon", "Dhubri", "Dibrugarh", "Guwahati", "Hojai/Lumding",
      "Lakhimpur", "Nalbari", "Patshala", "Silchar", "Sivsagar", "Tezpur", "Jorhat"
    ],
    "West Bengal": [
      "Alipurduar", "Asansol", "Bankura", "Barasat", "Basir hat", "Behrampur", "Burdawan",
      "Cooch Behar", "Dankuni", "Darjeeling", "Haldia", "Kaleempung", "Krishnanagar",
      "Malda", "Mecheda", "North 24 Pargana", "North Barrackpore", "Panchkura", "Purba Burdawan",
      "Purba Medinipur", "Raignaj", "Ranaghat", "Siliguri", "Sonapur", "Chandan Nagar", "Chinsurah",
      "Maynaguri", "Metli", "Sambalpur", "Durgapur", "Kharagpur", "Nadia"
    ],
    "Odisha": [
      "Balangir", "Balasore", "Bargarh","Baripada", "Bhadrak", "Bhubaneswar","Brahmapur", "Cuttack", "Jeypore",
      "Jharsuguda", "Puri", "Rourkela", "Sambalpur","Talcher"
    ]
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form default behavior (reloading the page)
    const form = event.currentTarget;

    // Validate form fields
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true); // Set form as validated

    // Prepare all the form data to send
    const dataToSend = {
      ...formData,      // Name, Mobile_No, Email, Investment, and Message
      Select_State: state,  // Select_State
      Select_City: city    // Select_City
    };

    // Sending form data using axios
    axios.post("https://api.ocupiiehospitalityacademy.com/api/business_enquiries", dataToSend)
    
      .then(response => {
        if (response.status === 201) {
          // Handle success response
          alert('Form submitted successfully!');

          // Reset form fields
          setFormData({
            Name: '',
            Mobile_No: '',
            Email: '',
            Investment: '',
            Message: ''
          });
          setState(''); // Reset the state field
          setCity(''); // Reset the city field
        } else {
          // Handle non-200 responses
          alert('Something went wrong. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error submitting the form:', error.response ? error.response.data : error.message);
        alert('Failed to submit the form. Please try again later.');
      });
  };

  // Update cities based on the selected state
  const handleStateChange = (e) => {
    setState(e.target.value);
    setCity(''); // Reset city when state changes
  };

  return (
    <Container className='p-0'>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className="enquiry-formff">
        <h4>Business Enquiry Form</h4>

        {/* Name Field */}
        <Form.Group controlId="name" className="mb-1">
          <FloatingLabel label="Full Name">
            <Form.Control
              type="text"
              name="Name" // Match backend field name
              value={formData.Name}
              onChange={handleInputChange}
              placeholder="Full Name"
              maxLength={255} // Corresponds to the backend rule
              required
            />
            <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Mobile Number Field */}
        <Form.Group controlId="mobile" className="mb-1">
          <FloatingLabel label="Phone Number">
            <Form.Control
              type="tel"
              name="Mobile_No" // Match backend field name
              value={formData.Mobile_No}
              onChange={handleInputChange}
              placeholder="Phone Number"
              pattern="[0-9]{10,12}" // Digits between 10-12
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid phone number (10-12 digits).</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Email Field */}
        <Form.Group controlId="email" className="mb-1">
          <FloatingLabel label="Email Address">
            <Form.Control
              type="email"
              name="Email" // Match backend field name
              value={formData.Email}
              onChange={handleInputChange}
              placeholder="Email Address"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Investment Field */}
        <Form.Group controlId="investment" className="mb-1">
          <FloatingLabel label="Min. Investment 5 Lakh">
            <Form.Control
              type="number"
              name="Investment" // Match backend field name
              value={formData.Investment}
              onChange={handleInputChange}
              placeholder="Investment Amount"
              min="500000"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid investment amount (min 5 lakh).</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* State Selection */}
        <Form.Group controlId="state" className="mb-1">
          <FloatingLabel label="Select State">
            <Form.Select value={state} onChange={handleStateChange} required>
              <option value="">Select State</option>
              {Object.keys(stateCities).map((stateName) => (
                <option key={stateName} value={stateName}>
                  {stateName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* City Selection */}
        <Form.Group controlId="city" className="mb-1">
          <FloatingLabel label="Select City">
            <Form.Select value={city} onChange={(e) => setCity(e.target.value)} required disabled={!state}>
              <option value="">Select City</option>
              {state && stateCities[state].map((cityName) => (
                <option key={cityName} value={cityName}>
                  {cityName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a city.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Message Field */}
        <Form.Group controlId="message" className="mb-1">
          <FloatingLabel label="Your Message">
            <Form.Control
              as="textarea"
              name="Message" // Match backend field name
              value={formData.Message}
              onChange={handleInputChange}
              placeholder="Your Message"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a message.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-end align-items-end">
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </Container>
  );
};

export default BusinessEnquiryForm;
