import React, { useRef, useEffect } from 'react'

function RestaurantManagementTraining({selectedSection}) {


    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("RestaurantManagementTraining");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Restaurant Management Training</h2>
                <p> This course is designed to provide participants with the essential skills and knowledge required to manage a successful restaurant. The course covers topics such as menu planning, staff management, customer service, financial management, and marketing, preparing participants for leadership roles in the restaurant industry.
                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 6 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>3 days per week,</p>
                <p>3 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <>
                    <h4>Module 1</h4>
                    <h5><strong>Introduction to Restaurant Management</strong></h5>
                    <ul>
                        <li>Overview of the Restaurant Industry</li>
                        <li>The Role of a Restaurant Manager</li>
                        <li>Key Success Factors in Restaurant Management</li>
                    
                    </ul>
                </>    
            

                 <>
                    <h4>Module 2</h4>
                    <h5><strong>Menu Planning and Development</strong></h5>
                    <ul>
                        <li>Principles of Menu Design</li>
                        <li>Balancing Cost, Quality, and Variety</li>
                        <li>Menu Engineering and Profitability Analysis</li>
                        <li>Adapting the Menu to Seasonal Changes and Customer Preferences</li>
                    </ul>
                </>

                <>
                    <h4>Module 3</h4>
                    <h5><strong>Staff Management and Leadership</strong></h5>
                    <ul>
                        <li>Recruiting, Hiring, and Training Staff</li>
                        <li>Building and Leading a High-Performance Team</li>
                        <li>Staff Scheduling and Labor Cost Management</li>
                        <li>Conflict Resolution and Staff Motivation Techniques  </li>
                    </ul>
                </>
                
                 <>
                    <h4>Module 4</h4>
                    <h5><strong>Customer Service Excellence</strong></h5>
                    <ul>
                        <li>Understanding Customer Expectations and Needs</li>
                        <li>Implementing Service Standards and Procedures</li>
                        <li>Handling Customer Complaints and Feedback</li>
                        <li>Enhancing the Guest Experience</li>
                    </ul>
                </>
 
                <>
                    <h4>Module 5</h4>
                    <h5><strong>Financial Management</strong></h5>
                    <ul>
                        <li>Budgeting and Financial Planning for Restaurants</li>
                        <li>Cost Control and Inventory Management</li>
                        <li>Pricing Strategies to Maximize Profit</li>
                        <li>Analyzing Financial Statements and Performance Metrics</li>
                    </ul>
                </>
                
                 <>
                    <h4>Module 6</h4>
                    <h5><strong>Marketing and Sales Strategies</strong></h5>
                    <ul>
                        <li>Developing a Restaurant Brand Identity</li>
                        <li>Digital Marketing and Social Media Strategies</li>
                        <li>Promotions, Events, and Loyalty Programs</li>
                        <li>Partnering with Food Delivery Services and Online Platforms</li>
                    </ul>
                </>
  
                <>
                    <h4>Module 7</h4>
                    <h5><strong>Operations and Quality Control</strong></h5>
                    <ul>
                        <li>Managing Day-to-Day Restaurant Operations</li>
                        <li>Ensuring Compliance with Health and Safety Regulations</li>
                        <li>Quality Control in Food Preparation and Service</li>
                        <li>Implementing Sustainable Practices in the Restaurant</li>
                    </ul>
                </>


            </div>

        </>

    
  )
}

export default RestaurantManagementTraining