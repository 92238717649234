import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './HospitalityIndustries.css'
import { Link } from 'react-router-dom';


function HospitalityIndustries() {
  return (
    <div className="d-flex justify-content-around cardDivClass" >
      <Card style={{ width: '18rem', height: '360px'}} className='cardClasss'>
        <Card.Img variant="top" src="images/hospitaly.png" className='cardImagg'/>
        <Card.Body className='cardCodyClass'>
          <Card.Title className='cardTitle'>Hospitality Industry</Card.Title>
          <Card.Text className='cardText'>
          The hospitality industry is one of the most dynamic and diverse sectors in the world, offering a wide range...
          </Card.Text>
          <Link to="/hospitality-industries-page">
          <Button variant="success" className='cardbutton'>Know More</Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}


export default HospitalityIndustries;




