import React, { useRef, useEffect } from 'react';

const GermanCourseDetails = ({ selectedSection }) => {
  const courseOverviewRef = useRef(null);
  const syllabusRef = useRef(null);
  const durationRef = useRef(null);
  const sessionsRef = useRef(null);


  useEffect(() => {
    console.log("german")
    if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedSection]);


  return (
    <>
     <div ref={courseOverviewRef} className="mb-4">
        <h2>German Course Overview</h2>
        <p>This course is designed to help participants
 develop their German language skills,
 focusing on speaking, listening, reading, and
 writing. The course is suitable for learners at
 various proficiency levels, aiming to improve
 their communication skills for personal,
 academic, or professional purposes.
</p>
      </div>
      
      <div ref={durationRef} className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 8 weeks</p>
      </div>

      <div ref={sessionsRef} className="mb-4">
        <h2>Sessions</h2>
        <p>3 days per week,</p>
        <p>1.5 hours per session</p>
      </div>
      <div ref={syllabusRef} className="mb-4">
        <h2>Syllabus</h2>
        <><h4>Module 1</h4>
           <h5><strong>Introduction to German Language</strong></h5>    
            <ul>
            <li>German Alphabet and Pronunciation</li>
            <li>Basic Greetings and Introductions</li>
            <li> Numbers, Days of the Week, and Months</li>
            <li>Simple Questions and Answers</li>
        </ul>
        </>
    
        <><h4>Module 2</h4>
           <h5><strong>Everyday Vocabulary and Phrases</strong></h5>    
            <ul>
            <li>Common Nouns, Verbs, and Adjectives</li>
            <li>Talking About Family, Friends, and Occupations</li>
            <li>Describing Daily Routines and Activities</li>
            <li>Shopping, Dining, and Travel Vocabulary</li>
        </ul>
        </>
        <><h4>Module 3</h4>
           <h5><strong>Basic Grammar</strong></h5>    
            <ul>
            <li>Introduction to German Sentence Structure</li>
            <li>Present Tense of Regular and Irregular Verbs</li>
            <li>Gender and Case System (Nominative, Accusative,
                Dative, Genitive)</li>
            <li>Forming Questions and Negatives</li>
        </ul>
        </>

        <><h4>Module 4</h4>
           <h5><strong>Conversational German</strong></h5>    
            <ul>
            <li> Engaging in Basic Conversations: Asking for Directions, Making
            Plans</li>
            <li>Discussing Hobbies, Interests, and Leisure Activities</li>
            <li>Talking About Weather, Time, and Dates</li>
            <li>Role-Playing Common Situations: At the Airport, Hotel, and
            Restaurant</li>
        </ul>
        </>
 
        <><h4>Module 5</h4>
           <h5><strong>Reading and Writing</strong></h5>    
            <ul>
            <li>Reading Simple Texts: Short Stories, Dialogues, and
            Articles</li>
            <li>Writing Basic Sentences and Paragraphs</li>
            <li>Composing Emails, Letters, and Messages in German</li>
            <li>Introduction to German Literature and Poetry</li>
        </ul>
        </>
    
        <><h4>Module 6</h4>
           <h5><strong>Listening and Comprehension</strong></h5>    
            <ul>
            <li>Listening to Native German Speakers: Audio Clips,
            Songs, and Videos</li>
            <li>Comprehending Spoken German in Various Contexts</li>
            <li>Practicing Listening Skills Through Interactive Exercises</li>
            <li>Understanding Accents and Regional Variations</li>
        </ul>
        </>
      
        <>
        <h4>Module 7</h4>
           <h5><strong>German Culture and Customs</strong></h5>    
            <ul>
            <li>Introduction to German-Speaking Countries and
            Cultures</li>
            <li>Understanding Social Etiquette and Traditions</li>
            <li>Exploring German Festivals, Music, and Cuisine</li>
            <li>Cultural Differences and Similarities</li>
        </ul>
        </>
      </div> 
 
    </>
  );
};


export default GermanCourseDetails;
