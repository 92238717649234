import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import FooterHome from './Home/Footer/FooterHome';
import NavBar from './Home/NavBar/NavBar';
import Home from './Home/Home';
import CoursePage from './Home/CoursesPage/CoursePage';
import HospitalityCareerPage from './Home/Hospitality/HospitalityCareerPage';
import HospitalityGuidenceCareerPage from './Home/Hospitality/HospitalityGuidenceCareerPage';
import HospitalityIndustriesPage from './Home/Hospitality/HospitalityIndustriesPage';
import ScrollToTop from './Home/ScrollToTop/ScrollToTop';
import BusinessEnquiryForm from './Home/EnquiryForm/BusinessEnquiryForm';
import LanguageOnline from './Home/LangugeOnline/LanguageOnline';
import Franchise from './Home/Franchise/Franchise';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ConditionalNavBar /> 
      <Routes>
        {/* Home Page */}
        <Route exact path="/" element={<Home />} />
        <Route path="/hospitality-industries-page" element={<HospitalityIndustriesPage />} />
        <Route path="/hospitality-guidence-career-page" element={<HospitalityGuidenceCareerPage />} />
        <Route path="/hospitality-career-page" element={<HospitalityCareerPage />} />
        <Route path="/business-enquiry" element={<BusinessEnquiryForm />} />
        {/* Dynamic Course Pages */}
        <Route path="/courses" element={<CoursePage />} />
        <Route path="/courses/:courseId" element={<CoursePage />} />
        <Route path="/onlinelanguages" element={<LanguageOnline />} />
        <Route path="/franchise" element={<Franchise/>}/>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <FooterHome />
    </Router>
  );
}

// Component to conditionally render the NavBar
const ConditionalNavBar = () => {
  const location = useLocation();

  // Define the routes where the Navbar should NOT be displayed
  const noNavbarRoutes = ['/onlinelanguages']; // Add your specific route here

  // Render the NavBar only if the current path is NOT in the noNavbarRoutes array
  return !noNavbarRoutes.includes(location.pathname) ? <NavBar /> : null;
};

export default App;
