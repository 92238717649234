    import React, { useRef, useEffect } from 'react'

function FrontOfficeManagementTraining({selectedSection}) {

    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("FrontOfficeManagementTraining");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Front Office Management Training</h2>
                <p> This course is tailored to equip participants with the essential skills and knowledge required to manage front office operations in the hospitality industry. Participants will learn about guest services, reservation systems, communication, and administrative tasks, preparing them for roles such as front desk agents, receptionists, and front office managers.
                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 5 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>3 days per week,</p>
                <p>3 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <>
                    <h4>Module 1</h4>
                    <h5><strong>Introduction to Front Office Operations</strong></h5>
                    <ul>
                        <li>Overview of the Front Office Department</li>
                        <li>Organizational Structure and Key Roles</li>
                        <li>Importance of the Front Office in Guest Satisfaction</li>
                        
                    </ul>
                </>    
 
                 <>
                    <h4>Module 2</h4>
                    <h5><strong>Guest Services and Communication</strong></h5>
                    <ul>
                        <li>Greeting and Welcoming Guests</li>
                        <li>Handling Guest Inquiries and Providing Information</li>
                        <li>Effective Communication Skills</li>
                        
                    </ul>
                </>


                <>
                    <h4>Module 3</h4>
                    <h5><strong>Reservation Management</strong></h5>
                    <ul>
                        <li>Introduction to Reservation Systems (e.g., PMS)</li>
                        <li>Making and Modifying Reservations</li>
                        <li>Managing Room Availability and Overbooking</li>
                        
                    </ul>
                </>
            
                 <>
                    <h4>Module 4</h4>
                    <h5><strong>Check-In and Check-Out Procedures</strong></h5>
                    <ul>
                        <li>Standard Check-In Procedures</li>
                        <li>Handling Different Types of Guests (e.g., VIPs, Groups)</li>
                        <li>Efficient Check-Out and Billing Processes</li>
                        
                    </ul>
                </>
                
                <>
                    <h4>Module 5</h4>
                    <h5><strong>Managing Guest Complaints and Special Requests</strong></h5>
                    <ul>
                        <li>Common Guest Complaints and Solutions</li>
                        <li>Handling Difficult Situations with Diplomacy</li>
                        <li>Managing Special Requests and Personalizing Guest Experiences</li>
                    
                    </ul>
                </>
            
                 <>
                    <h4>Module 6</h4>
                    <h5><strong>Administrative Duties and Reporting</strong></h5>
                    <ul>
                        <li>Daily Front Office Reports and Logs</li>
                        <li>Managing Cash Floats and Petty Cash</li>
                        <li>Coordinating with Housekeeping and Other Departments</li>
                    </ul>
                </>
                 
                <>
                    <h4>Module 7</h4>
                    <h5><strong>Safety, Security, and Emergency Procedures</strong></h5>
                    <ul>
                        <li>Ensuring Guest and Staff Safety</li>
                        <li>Handling Emergency Situations</li>
                        <li>Security Protocols in the Front Office</li>
                    </ul>
                </>
                 
            </div>

        </>
  )
}

export default FrontOfficeManagementTraining