    import React, { useRef, useEffect } from 'react'

function RoomServiceSkillsTraining({selectedSection}) {

    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("RoomServiceSkillsTraining");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Room Service Skills Training</h2>
                <p> This course is designed to equip participants with the essential skills and knowledge required to deliver high-quality room service in hospitality settings, such as hotels and resorts. Participants will learn about customer service, food and beverage handling, safety protocols, and communication skills.
                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 4 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>3 days per week,</p>
                <p>3 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <>
                    <h4>Module 1</h4>
                    <h5><strong>Introduction to Room Service</strong></h5>
                    <ul>
                        <li>Overview of Room Service in Hospitality</li>
                        <li>Understanding Guest Expectations</li>
                        <li>The Role of Room Service Staff</li>
                    </ul>
                </>    
 
                 <>
                    <h4>Module 2</h4>
                    <h5><strong>Food and Beverage Handling</strong></h5>
                    <ul>
                        <li>Safe Food Handling Practices</li>
                        <li>Beverage Preparation and Presentation</li>
                        <li>Setting Up a Room Service Tray</li>
                    </ul>
                </>

                <>
                    <h4>Module 3</h4>
                    <h5><strong>Room Service Procedures</strong></h5>
                    <ul>
                        <li>Taking and Processing Orders</li>
                        <li>Delivering Orders to Guest Rooms</li>
                        <li>Timing and Efficiency in Service</li>
                        <li>Making Informed Decisions Under Pressure</li>
                    </ul>
                </>

                 <>
                    <h4>Module 4</h4>
                    <h5><strong>Customer Service Excellence</strong></h5>
                    <ul>
                        <li>Communication Skills</li>
                        <li>Managing Guest Requests and Complaints</li>
                        <li>Personalized Service and Attention to Detail</li>
                    </ul>
                </>
                
                <>
                    <h4>Module 5</h4>
                    <h5><strong>Safety and Hygiene Standards</strong></h5>
                    <ul>
                        <li>Personal Hygiene for Room Service Staff</li>
                        <li>Safety Protocols in Room Service</li>
                        <li>Handling Emergency Situations</li>
                    </ul>
                </>
            
                 <>
                    <h4>Module 6</h4>
                    <h5><strong>Practical Training and Role-Playing</strong></h5>
                    <ul>
                        <li>Simulated Room Service Scenarios</li>
                        <li>Role-Playing Exercises</li>
                        <li>Hands-on Practice with Supervision</li>
                    </ul>
                </>

            </div>

        </>
  )
}

export default RoomServiceSkillsTraining