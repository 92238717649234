import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

function FoodandBeverageManagementTraining({ selectedSection }) {

  useEffect(() => {
    if (selectedSection) {
      // Scroll to the selected section with smooth behavior
      scroller.scrollTo(selectedSection, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [selectedSection]);

  return (
    <>
      {/* Course Overview Section */}
      <Element name="courseOverview" className="mb-4">
        <h2>Food and Beverage Management Training</h2>
        <p>This course provides comprehensive training
          in managing food and beverage operations
          within the hospitality industry. Participants
          will gain knowledge in menu planning, cost
          control, service standards, and managing
          staff, preparing them for leadership roles in
          restaurants, hotels, and other food service
          establishments.
        </p>
      </Element>

      {/* Course Duration Section */}
      <Element name="duration" className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 6 weeks</p>
      </Element>

      {/* Sessions Section */}
      <Element name="sessions" className="mb-4">
        <h2>Sessions</h2>
        <p>3 days per week,</p>
        <p>3 hours per session</p>
      </Element>

      {/* Syllabus Section */}
      <Element name="syllabus" className="mb-4">
        <h2>Syllabus</h2>

        {/* Module 1 */}
        <h4>Module 1</h4>
        <h5><strong>Introduction to Food and Beverage Management</strong></h5>
        <ul>
          <li>Overview of the Food and Beverage Department</li>
          <li>Key Roles and Responsibilities</li>
          <li>Trends in the Food and Beverage Industry</li>
        </ul>

        {/* Module 2 */}
        <h4>Module 2</h4>
        <h5><strong>Menu Planning and Development</strong></h5>
        <ul>
          <li>Principles of Menu Design</li>
          <li>Balancing Cost, Quality, and Presentation</li>
          <li>Developing Special Menus (e.g., Dietary Restrictions, Seasonal)</li>
        </ul>

        {/* Module 3 */}
        <h4>Module 3</h4>
        <h5><strong>Cost Control and Budgeting</strong></h5>
        <ul>
          <li>Managing Food and Beverage Costs</li>
          <li>Inventory Control and Purchasing</li>
          <li>Budgeting and Financial Planning</li>
        </ul>

        {/* Module 4 */}
        <h4>Module 4</h4>
        <h5><strong>Service Standards and Guest Experience</strong></h5>
        <ul>
          <li>Types of Food and Beverage Service (e.g., Table Service, Buffet)</li>
          <li>Setting and Maintaining Service Standards</li>
          <li>Enhancing the Guest Dining Experience</li>
        </ul>

        {/* Module 5 */}
        <h4>Module 5</h4>
        <h5><strong>Food Safety and Hygiene</strong></h5>
        <ul>
          <li>Food Safety Regulations and Compliance</li>
          <li>Implementing Hygiene Standards in the Kitchen and Dining Area</li>
          <li>Staff Training on Food Handling and Safety</li>
        </ul>

        {/* Module 6 */}
        <h4>Module 6</h4>
        <h5><strong>Staff Management and Training</strong></h5>
        <ul>
          <li>Recruiting and Hiring Food and Beverage Staff</li>
          <li>Staff Training and Development</li>
          <li>Managing Staff Performance and Motivation</li>
        </ul>

        {/* Module 7 */}
        <h4>Module 7</h4>
        <h5><strong>Marketing and Sales in Food and Beverage</strong></h5>
        <ul>
          <li>Promoting Food and Beverage Services</li>
          <li>Designing and Implementing Sales Strategies</li>
          <li>Understanding Customer Preferences and Trends</li>
        </ul>
      </Element>
    </>
  );
}

export default FoodandBeverageManagementTraining;
