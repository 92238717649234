import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Language.css';

function LanguageTraning() {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  return (
    <Dropdown
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={show} // Controls the visibility of the dropdown
      key='end'
      drop='end'
      variant="secondary"
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Language
      </Dropdown.Toggle>

      <Dropdown.Menu className='Dropdown-Menu'>
        <Dropdown.Item as={Link} to="/courses/english">Learn English</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/french">Learn French</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/german">Learn German</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/spanish">Learn Spanish</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/arabic">Learn Arabic</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageTraning;
