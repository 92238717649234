import React from 'react'
import CoursesHospitality from './CoursesHospitality'
import CoursesSoftskill from './CoursesSoftskill'
import CousessLanguage from './CousessLanguage'

function CoursesHome() {
  return (
    <div>
        <CoursesHospitality/>
        <CoursesSoftskill/>
        <div>
        <CousessLanguage />
        </div>
        
    </div>
  )
}

export default CoursesHome
