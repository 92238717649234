import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

const EffectiveCommunicationSkillsTraining = ({ selectedSection }) => {

  useEffect(() => {
    if (selectedSection) {
      // Scroll to the selected section with smooth behavior
      scroller.scrollTo(selectedSection, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [selectedSection]);

  return (
    <>
      {/* Course Overview Section */}
      <Element name="courseOverview" className="mb-4">
        <h2>Effective Communication Skills Training</h2>
        <p>This course is designed to enhance participants' communication skills, focusing on verbal and non-verbal communication, active listening, and effective presentation techniques. It aims to equip learners with the tools to communicate confidently and effectively in personal and professional settings.</p>
      </Element>

      {/* Course Duration Section */}
      <Element name="duration" className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 6 weeks</p>
      </Element>

      {/* Sessions Section */}
      <Element name="sessions" className="mb-4">
        <h2>Sessions</h2>
        <p>2 days per week,</p>
        <p>2 hours per session</p>
      </Element>

      {/* Syllabus Section */}
      <Element name="syllabus" className="mb-4">
        <h2>Syllabus</h2>

        {/* Module 1 */}
        <h4>Module 1</h4>
        <h5><strong>Fundamentals of Communication</strong></h5>
        <ul>
          <li>Understanding the Communication Process</li>
          <li>Types of Communication: Verbal, Non-Verbal, and Written</li>
          <li>The Role of Context in Communication</li>
          <li>Identifying and Overcoming Common Communication Barriers</li>
        </ul>

        {/* Module 2 */}
        <h4>Module 2</h4>
        <h5><strong>Verbal Communication Skills</strong></h5>
        <ul>
          <li>Techniques for Clear and Concise Communication</li>
          <li>The Power of Language: Choosing the Right Words</li>
          <li>Tone of Voice and Its Impact on Communication</li>
          <li>Effective Storytelling in Communication</li>
        </ul>

        {/* Module 3 */}
        <h4>Module 3</h4>
        <h5><strong>Non-Verbal Communication</strong></h5>
        <ul>
          <li>Understanding Body Language and Facial Expressions</li>
          <li>The Role of Gestures, Posture, and Eye Contact</li>
          <li>Interpreting Non-Verbal Cues from Others</li>
          <li>Aligning Verbal and Non-Verbal Communication</li>
        </ul>

        {/* Module 4 */}
        <h4>Module 4</h4>
        <h5><strong>Active Listening</strong></h5>
        <ul>
          <li>Principles of Active Listening</li>
          <li>Techniques for Improving Listening Skills</li>
          <li>Empathy in Communication: Understanding and Responding to Others</li>
          <li>Handling Feedback and Constructive Criticism</li>
        </ul>

        {/* Module 5 */}
        <h4>Module 5</h4>
        <h5><strong>Public Speaking and Presentation Skills</strong></h5>
        <ul>
          <li>Overcoming Fear of Public Speaking</li>
          <li>Structuring a Presentation: Introduction, Body, and Conclusion</li>
          <li>Engaging the Audience: Techniques for Capturing Attention</li>
          <li>Using Visual Aids and Technology in Presentations</li>
        </ul>

        {/* Module 6 */}
        <h4>Module 6</h4>
        <h5><strong>Interpersonal Communication and Relationships</strong></h5>
        <ul>
          <li>Building Rapport and Trust in Communication</li>
          <li>Effective Communication in Teams and Group Settings</li>
          <li>Conflict Resolution and Negotiation Skills</li>
          <li>Managing Difficult Conversations with Confidence</li>
        </ul>

      </Element>
    </>
  );
};

export default EffectiveCommunicationSkillsTraining;
