import React, { useRef, useEffect } from 'react'


function MasteringHotelFinancials({selectedSection}) {
    
        const courseOverviewRef = useRef(null);
        const syllabusRef = useRef(null);
        const durationRef = useRef(null);
        const sessionsRef = useRef(null);
    
    
        useEffect(() => {
            console.log("MasteringHotelFinancials");
            if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
            if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
            if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
            if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, [selectedSection]);
    
    
        return (
            <>
                <div ref={courseOverviewRef} className="mb-4">
                    <h2>Mastering Hotel Financials</h2>
                    <p> This course provides participants with essential financial 
                    management skills tailored for the hospitality industry. The course covers
                     financial statement analysis, budgeting, forecasting, and revenue management, 
                     enabling participants to make informed 
                    decisions to maximize profitability and financial efficiency.
                    </p>
                </div>
    
                <div ref={durationRef} className="mb-4">
                    <h2>Course Duration</h2>
                    <p><strong>Total Duration:</strong> 6 weeks</p>
                </div>
    
                <div ref={sessionsRef} className="mb-4">
                    <h2>Sessions</h2>
                    <p>3 days per week,</p>
                    <p>3 hours per session</p>
                </div>
                <div ref={syllabusRef} className="mb-4">
                    <h2>Syllabus</h2>
                    <>
                        <h4>Module 1</h4>
                        <h5><strong>Introduction to Hotel Financial Management</strong></h5>
                        <ul>
                            <li>Overview of Financial Roles in a Hotel</li>
                            <li>Active Listening and Empathy</li>
                            <li>Key Financial Concepts and Terminology</li>
                            <li>The Role of Financial Management in Hotel Operations</li>
                        </ul>
                    </>    


                     <>
                        <h4>Module 2</h4>
                        <h5><strong>Understanding Financial Statements</strong></h5>
                        <ul>
                            <li>Reading and Interpreting the Income Statement</li>
                            <li>Analyzing the Balance Sheet and Cash Flow Statement</li>
                            <li>Key Performance Indicators (KPIs) in the Hospitality Industry</li>
                        
                        </ul>
                    </>
 	
                    <>
                        <h4>Module 3</h4>
                        <h5><strong>Budgeting and Forecasting</strong></h5>
                        <ul>
                            <li>Developing Operating Budgets for Different Departments</li>
                            <li>Capital Expenditures and Long-Term Financial Planning</li>
                            <li>Techniques for Accurate Forecasting and Budgeting</li>
                            <li>Variance Analysis and Budget Adjustments</li>
                        </ul>
                    </>
                    
                     <>
                        <h4>Module 4</h4>
                        <h5><strong>Cost Control and Profitability</strong></h5>
                        <ul>
                            <li>Identifying Fixed and Variable Costs</li>
                            <li>Cost Control Strategies in Housekeeping, F&B, and Front Office</li>
                            <li>Profitability Analysis and Break-Even Analysis</li>
                            <li>Strategies for Improving Operational Efficiency</li>
                        </ul>
                    </>
                    

                    <>
                        <h4>Module 5</h4>
                        <h5><strong>Revenue Management and Pricing Strategies</strong></h5>
                        <ul>
                            <li>Principles of Revenue Management</li>
                            <li>Dynamic Pricing and Demand Forecasting</li>
                            <li>Market Segmentation and Inventory Management</li>
                            <li>Maximizing Revenue Through Effective Pricing Strategies</li>
                        </ul>
                    </>
                    
                     <>
                        <h4>Module 6</h4>
                        <h5><strong>Financial Decision-Making and Reporting</strong></h5>
                        <ul>
                            <li>Financial Decision-Making Tools and Techniques</li>
                            <li>Preparing Financial Reports for Stakeholders</li>
                            <li>Using Financial Data to Support Strategic Decisions</li>
                            <li>Financial Risk Management in the Hospitality Industry</li>
                        </ul>
                    </>
   
                    
                </div>
    
            </>
    
    
  )
}

export default MasteringHotelFinancials