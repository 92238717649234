import React, { useState, useEffect, useCallback, useRef } from "react";
import { Navbar, Nav, Container, Form, Button, Modal,Image } from "react-bootstrap";
import { Link as RouterLink } from "react-router-dom";
import "./NavBar.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import logo from 'public/ocupiielogos.png';


function NavBar() {
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [info, setInfo] = useState("");
  const [expand, setExpand] = useState("lg"); // Set initial state
  const [navbarVisible, setNavbarVisible] = useState(true);

  // Ref for scroll tracking
  const lastScrollY = useRef(0);

  const handleAboutUsClick = () => {
    setInfo(
      <>
        <p>
          We are dedicated to shaping the future of hospitality by providing
          world-class education and training. Our academy is committed to
          empowering individuals with the skills and knowledge they need to
          thrive in the ever-evolving hospitality industry. We specialize in
          offering a wide range of programs, including{" "}
          <b>
            Hospitality management, Food & Beverage operations, Language
            training, Recruitment services, and Security training
          </b>
          . With a global perspective and a local presence in the
          <b> East Zone of India</b>, we focus on creating professionals who are
          ready to meet the demands of the international hospitality landscape.{" "}
        </p>
        <p>
          Our programs are designed with a blend of
          <b> theoretical knowledge and practical experience</b>, ensuring that
          our graduates are job-ready and equipped to excel in their careers.
          Whether you are looking to enter the hospitality field or enhance your
          existing skills, Ocupiie Hospitality Academy provides the right
          platform for your professional growth.
        </p>
      </>
    );
    setShowAboutUs(true);
  };

  const handleContactUsClick = () => setShowContactUs(true);
  const handleClose = () => {
    setShowAboutUs(false);
    setShowContactUs(false);
  };

  // Scroll event listener
  const handleScroll = useCallback(() => {
    const currentScrollY = window.pageYOffset;
    if (currentScrollY > lastScrollY.current) {
      setNavbarVisible(false); // Hide when scrolling down
    } else {
      setNavbarVisible(true); // Show when scrolling up
    }
    lastScrollY.current = currentScrollY <= 0 ? 0 : currentScrollY; // Update last scroll position
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // Handle responsive expand based on screen size
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 576) {
        setExpand("sm");
      } else if (screenWidth >= 576 && screenWidth < 768) {
        setExpand("md");
      } else if (screenWidth >= 768 && screenWidth < 992) {
        setExpand("lg");
      } else {
        setExpand("xl");
      }
    };

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Call once to set initial value
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand={expand}
      fixed="top"
      className={`navbarclass ${
        navbarVisible ? "navbar-visible" : "navbar-hidden"
      } m-0 p-0 px-2`}
    >
      <Container xs="auto" fluid={true} className="p-0 m-0 me-auto">
        <Navbar.Brand as={RouterLink} to="/">
          <Image
            src={`${process.env.PUBLIC_URL}/ocupiielogos.png`}
            alt="OCUPIIE"
            className="logoClasss"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto navlistclass">
            <Nav.Link as={RouterLink} to="/" className="my-custom-link">
              Home
            </Nav.Link>
            <Nav.Link onClick={handleAboutUsClick} className="my-custom-link">
              About Us
            </Nav.Link>
            <Nav.Link as={RouterLink} to="/franchise" className="my-custom-link">
              Franchise
            </Nav.Link>
            
            <Nav.Link as={RouterLink} to="/onlinelanguages" className="my-custom-link">
              Learn Online
            </Nav.Link>
            <Nav.Link onClick={handleContactUsClick} className="my-custom-link">
              Contact Us
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control type="search" placeholder="Search" className="me-2" />
            <Button variant="outline-success" className="bi bi-search" />
          </Form>
        </Navbar.Collapse>
      </Container>


  {/* Modal for About Us information */}
  <Modal show={showAboutUs} onHide={handleClose} className="modelbodyaboutusss" >
        <Modal.Header closeButton style={{ backgroundColor: "#fbf5ed" }}>
          <Modal.Title>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{backgroundImage: `url('/images/aboutus.png')`,padding:'50px', backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}} className="px-5">{info}</Modal.Body>
      
      </Modal>


      <Modal show={showContactUs} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <p>
              <strong>Phone:</strong>{" "}
              <a href="tel:+91 36140 52618">+91 36140 52618</a>
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a href="mailto:contact@ocupiie.com">contact@ocupiie.com</a>
            </p>
          </Container>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}

export default NavBar;
