import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState } from 'react';
import './SoftSkill.css';
import { Link } from 'react-router-dom';

function SoftSkill() {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  return (
    <Dropdown
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={show} // Controls the visibility of the dropdown
      key='end'
      drop='end'
      variant="secondary"
    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Soft-Skill
      </Dropdown.Toggle>

      <Dropdown.Menu className='Dropdown-Menu'>
        <Dropdown.Item as={Link} to="/courses/comprehensivesoftskillsdevelopment">Comprehensive Soft Skills Development</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/effectivecommunicationskillstraining">Effective Communication Skills Training</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/personalitydevelopmentandsoftskillstraining">Personality Development and Soft Skills Training</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/groomingandetiquette">Professional Grooming and Etiquette Training</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SoftSkill;
