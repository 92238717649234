import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppButton.css';
import { Button } from 'react-bootstrap';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+916003344113', '_blank');
  };

  return (
    <Button variant="success" className="whatsapp-button fixed-button" onClick={handleWhatsAppClick}>
      <FaWhatsapp size={40} color="#25D366" />
    </Button>
    
  );
};

export default WhatsAppButton;
