import React, { useState } from 'react';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const statesList = [
  'Andaman and Nicobar Islands', 'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar',
  'Chandigarh', 'Chhattisgarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Delhi',
  'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka',
  'Kerala', 'Ladakh', 'Lakshadweep', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
  'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Puducherry', 'Punjab', 'Rajasthan',
  'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand',
  'West Bengal', 'Jammu and Kashmir'
];

const EnquiryFormLanguage = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    Mobile: '',
    Email: '',
    State: '',
    Language: '',
    Learn: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Check if the form is valid
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const dataTosend = {
      FullName: formData.Name,
      Email: formData.Email,
      Mobile: formData.Mobile,
      Select_state: formData.State,
      Choose_Language: formData.Language,
      Select_Prefrence: formData.Learn
    };

    setValidated(true);

    // Send the form data directly without OTP
    axios.post("http://127.0.0.1:8000/api/language-enquiry/submit", dataTosend)
      .then((response) => {
        if (response.status === 201) {
          alert('Form submitted successfully!');
          setFormData({
            Name: '',
            Mobile: '',
            Email: '',
            State: '',
            Language: '',
            Learn: ''
          });
        } else {
          alert('Something went wrong. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error submitting the form:', error.response ? error.response.data : error.message);
        alert('Failed to submit the form. Please try again later.');
      });
  };

  return (
    <Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h4>Language Enquiry Form</h4>

        {/* Name Field */}
        <Form.Group controlId="name" className="mb-1">
          <FloatingLabel label="Full Name">
            <Form.Control
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleInputChange}
              placeholder="Full Name"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Mobile Number Field */}
        <Form.Group controlId="mobile" className="mb-1">
          <FloatingLabel label="Phone Number">
            <Form.Control
              type="tel"
              name="Mobile"
              value={formData.Mobile}
              onChange={handleInputChange}
              placeholder="Phone Number"
              pattern="[0-9]{10,12}" // Digits between 10-12
              maxLength={12}
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid phone number.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Email Field */}
        <Form.Group controlId="email" className="mb-1">
          <FloatingLabel label="Email Address">
            <Form.Control
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleInputChange}
              placeholder="Email Address"
              pattern="[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+)*@[a-zA-Z0-9_][\-a-zA-Z0-9_]*(\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
          
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* State Selection */}
        <Form.Group controlId="state" className="mb-1">
          <FloatingLabel label="Select State">
            <Form.Select
              name="State"
              value={formData.State}
              onChange={handleInputChange}
              required
            >
              <option value="">Select State</option>
              {statesList.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Choose Language Selection */}
        <Form.Group controlId="language" className="mb-1">
          <FloatingLabel label="Choose Language">
            <Form.Select
              name="Language"
              value={formData.Language}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Language</option>
              <option value="English">English</option>
              <option value="Arabic">Arabic</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a language.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* I Want to Learn Selection */}
        <Form.Group controlId="learn" className="mb-1">
          <FloatingLabel label="I Want to Learn">
            <Form.Select
              name="Learn"
              value={formData.Learn}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Preference</option>
              <option value="Basic">Single</option>
              <option value="Advanced">Group</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a course level.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-end align-items-end">
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </Container>
  );
};

export default EnquiryFormLanguage;