import React, { useRef, useEffect } from 'react'

function KitchenOperationsTraining({selectedSection}) {

    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("KitchenOperationsTraining");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Kitchen Operations Training</h2>
                <p> This course is designed to provide participants with the knowledge and skills required to effectively manage kitchen operations in a professional setting. The course covers food preparation, kitchen safety, inventory management, and team coordination, preparing participants for roles such as kitchen supervisors, chefs, and kitchen managers.
                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 6 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>3 days per week,</p>
                <p>4 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <>
                    <h4>Module 1</h4>
                    <h5><strong>Introduction to Kitchen Operations</strong></h5>
                    <ul>
                        <li>Overview of Professional Kitchens</li>
                        <li>Key Roles and Responsibilities in a Kitchen</li>
                        <li>Understanding Kitchen Workflows and Layouts</li>
                    </ul>
                </>    
 
                 <>
                    <h4>Module 2</h4>
                    <h5><strong>Food Preparation and Cooking Techniques</strong></h5>
                    <ul>
                        <li>Basic and Advanced Cooking Techniques</li>
                        <li>Time Management in Food Preparation</li>
                        <li>Plating and Presentation Standards</li>
                    </ul>
                </>
                 

                <>
                    <h4>Module 3</h4>
                    <h5><strong>Kitchen Safety and Hygiene</strong></h5>
                    <ul>
                        <li>Personal Hygiene and Grooming Standards</li>
                        <li>Kitchen Safety Protocols (e.g., Fire Safety, Equipment Handling)</li>
                        <li>Food Safety and Sanitation Practices</li>
                        
                    </ul>
                </>
 
                 <>
                    <h4>Module 4</h4>
                    <h5><strong>Inventory and Stock Management</strong></h5>
                    <ul>
                        <li>Ordering and Receiving Supplies</li>
                        <li>Managing Inventory and Minimizing Waste</li>
                        <li>Storage Techniques for Different Food Items</li>
                    </ul>
                </>
                
                <>
                    <h4>Module 5</h4>
                    <h5><strong>Staff Coordination and Communication</strong></h5>
                    <ul>
                        <li>Supervising and Training Kitchen Staff</li>
                        <li>Effective Communication in a High-Pressure Environment</li>
                        <li>Conflict Resolution and Teamwork in the Kitchen</li>
                    </ul>
                </>

                 <>
                    <h4>Module 6</h4>
                    <h5><strong>Waste Management and Sustainability</strong></h5>
                    <ul>
                        <li>Strategies for Reducing Food Waste</li>
                        <li>Ethical Decision-Making in the Workplace</li>
                        <li>Implementing Eco-Friendly Practices in the Kitchen</li>
                    
                    </ul>
                </>
                 
                <>
                    <h4>Module 7</h4>
                    <h5><strong>Practical Training and Real-Life Scenarios</strong></h5>
                    <ul>
                        <li>Hands-On Food Preparation Exercises</li>
                        <li>Simulating a Busy Kitchen Environment</li>
                        <li>Role-Playing and Problem-Solving Scenarios</li>
                    
                    </ul>
                </>
                 
             </div>
        </>
  )
}

export default KitchenOperationsTraining