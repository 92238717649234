import React, { useRef, useEffect } from 'react'

function HotelMarketingStrategiesTraining({selectedSection}) {
  

    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("HotelMarketingStrategiesTraining");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Hotel Marketing Strategies Training</h2>
                <p>This course is designed to equip participants with the 
                knowledge and skills needed to effectively market hotels and 
                hospitality services. The course covers digital marketing, branding,
                 customer relationship management, and sales strategies, preparing participants 
                 to enhance a hotel's visibility and profitability.
                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 5 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>3 days per week,</p>
                <p>3 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <>
                    <h4>Module 1</h4>
                    <h5><strong>Introduction to Hotel Marketing</strong></h5>
                    <ul>
                        <li>Overview of the Hospitality Industry</li>
                        <li>The Role of Marketing in Hotel Success</li>
                        <li>Key Marketing Concepts and Theories</li>
                    </ul>
                </>    
 
                 <>
                    <h4>Module 2</h4>
                    <h5><strong>Branding and Positioning</strong></h5>
                    <ul>
                        <li>Building a Strong Hotel Brand</li>
                        <li>Differentiating Your Hotel from Competitors</li>
                        <li>Developing a Unique Selling Proposition (USP)</li>
                    </ul>
                </>

                <>
                    <h4>Module 3</h4>
                    <h5><strong>Digital Marketing for Hotels</strong></h5>
                    <ul>
                        <li>Overview of Digital Marketing Channels (e.g., Social Media, SEO, PPC)</li>
                        <li>Content Marketing and Blogging for Hotels</li>
                        <li>Managing Online Reviews and Reputation</li>
                    </ul>
                </>
      
                 <>
                    <h4>Module 4</h4>
                    <h5><strong>Customer Relationship Management (CRM)</strong></h5>
                    <ul>
                        <li>Understanding Customer Segmentation</li>
                        <li>Developing Loyalty Programs and Incentives</li>
                        <li>Using CRM Tools to Manage Guest Data</li>
                    </ul>
                </>
                
                <>
                    <h4>Module 5</h4>
                    <h5><strong>Sales and Promotional Strategies</strong></h5>
                    <ul>
                        <li>Designing Effective Sales Promotions</li>
                        <li>Packaging and Pricing Strategies</li>
                        <li>Collaborating with Travel Agents and Online Travel Agencies (OTAs)</li>
                    </ul>
                </>

                 <>
                    <h4>Module 6</h4>
                    <h5><strong>Market Research and Analysis</strong></h5>
                    <ul>
                        <li>Conducting Market Research for Hotels</li>
                        <li>Analyzing Competitor Strategies</li>
                        <li>Understanding Guest Preferences and Trends</li>
                    </ul>
                </>
                 
                <>
                    <h4>Module 7</h4>
                    <h5><strong>Practical Application and Case Studies</strong></h5>
                    <ul>
                        <li>Developing a Marketing Plan for a Hotel</li>
                        <li>Case Studies of Successful Hotel Marketing Campaigns</li>
                        <li>Role-Playing Marketing Scenarios</li>
                    </ul>
                </>
                 
             </div>

        </>

  )
}

export default HotelMarketingStrategiesTraining