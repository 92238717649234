import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Buttons.css';
import Hospitality from './DropDown/Hospitality';
import LanguageTraning from './DropDown/LanguageTraning';
import SoftSkill from './DropDown/SoftSkill';

const Buttons = () => {
  return (
    <Container fluid={true}
      className="container-bg-color p-0  m-0 d-flex flex-column justify-content-center align-items-center"
    >
      <Row
        className="justify-content-between align-items-center row-item"
      >
        <Col lg={3}
          className="col-item d-flex justify-content-center align-items-center"
        >
          <img src="images/hospitality.png" alt="Hospitality" width="80"/>
        </Col>

        <Col lg={3}
          className="col-item d-flex justify-content-center align-items-center"
        >
          <img src="images/softskills.png" alt="Soft Skill" width="80" />
        </Col>

        <Col lg={3}
          className="col-item d-flex justify-content-center align-items-center"
        >
          <img src="images/languagetraining.png" alt="Languages" width="80" />
        </Col>
      </Row>

      <Row
        className="justify-content-between align-items-center g-5 m-0 p-0 mt-2 rowitemss"
        
      >
        <Col xs={4} lg={3} className=" justify-content-center align-items-left m-0 p-0 colsecondcol">
          <Hospitality />
        </Col>

        <Col  xs={4} lg={3} className="d-flex justify-content-center align-items-center m-0 p-0 colsecondcol">
          <SoftSkill />
        </Col>

        <Col xs={4} lg={3} className=" justify-content-center align-items-right m-0 p-0 colsecondcol colomn3">
          <LanguageTraning />
        </Col>
      </Row>
    </Container>
  );
};

export default Buttons;
