import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Slide from './Slider/Slide'
import Buttons from './Button/Buttons';
import Hospitality from './Hospitality/Hospitality';
import CoursesHome from './CoursesHome/CoursesHome';
import MissionVision from './MissionAndVision/MisionVision';
import WhyChoose from './WhyChooseUs/WhyChoose';
import VectorImage from './VectorImage/VectorImage';
import Onlinelearn from './OnlineLearn/Onlinelearn';
import WhatsAppButton from './WhatsAppButton/WhatsAppButton';

function Home() {
  return (
    <>
    
         <Slide />  

        <Buttons />
            
        <VectorImage />
    
        <Hospitality />
      
        <Onlinelearn/>
 
        <CoursesHome />
      
        <MissionVision/>
     
        <WhyChoose/>
        <WhatsAppButton/>
       
       
      
    </>
  )
}

export default Home