import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

const EnglishCourseDetail = ({ selectedSection }) => {

  useEffect(() => {
    if (selectedSection) {
      // Scroll to the selected section with smooth behavior
      scroller.scrollTo(selectedSection, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [selectedSection]);

  return (
    <>
      {/* Course Overview Section */}
      <Element name="courseOverview" className="mb-4">
        <h2>English Course Overview</h2>
        <p>
          This course is designed to help participants develop their English language skills,
          focusing on speaking, listening, reading, and writing. The course is suitable for
          learners at various proficiency levels, aiming to improve their communication
          skills for personal, academic, or professional purposes.
        </p>
      </Element>

      {/* Course Duration Section */}
      <Element name="duration" className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 12 weeks</p>
      </Element>

      {/* Sessions Section */}
      <Element name="sessions" className="mb-4">
        <h2>Sessions</h2>
        <p>3 days per week,</p>
        <p>2 hours per session</p>
      </Element>

      {/* Syllabus Section */}
      <Element name="syllabus" className="mb-4">
        <h2>Syllabus</h2>

        {/* Module 1 */}
        <h4>Module 1</h4>
        <h5><strong>Introduction to English Language</strong></h5>
        <ul>
          <li>Overview of the English Language</li>
          <li>The Importance of English in Global Communication</li>
          <li>Assessing Current Proficiency Levels</li>
        </ul>

        {/* Module 2 */}
        <h4>Module 2</h4>
        <h5><strong>Grammar and Sentence Structure</strong></h5>
        <ul>
          <li>Understanding Basic Grammar Rules</li>
          <li>Sentence Structure and Syntax</li>
          <li>Common Grammatical Errors and How to Avoid Them</li>
          <li>Practice Exercises in Grammar Usage</li>
        </ul>

        {/* Module 3 */}
        <h4>Module 3</h4>
        <h5><strong>Vocabulary Building</strong></h5>
        <ul>
          <li>Expanding Vocabulary for Everyday Use</li>
          <li>Learning Common Idioms and Phrasal Verbs</li>
          <li>Contextual Vocabulary for Specific Situations</li>
          <li>Techniques for Remembering New Words</li>
        </ul>

        {/* Module 4 */}
        <h4>Module 4</h4>
        <h5><strong>Speaking and Pronunciation</strong></h5>
        <ul>
          <li>Improving Pronunciation and Intonation</li>
          <li>Practice in Conversational English</li>
          <li>Public Speaking and Presentation Skills</li>
          <li>Role-Playing Real-Life Scenarios</li>
        </ul>

        {/* Module 5 */}
        <h4>Module 5</h4>
        <h5><strong>Listening and Comprehension</strong></h5>
        <ul>
          <li>Techniques for Active Listening</li>
          <li>Understanding Different English Accents</li>
          <li>Listening Practice with Audio and Video Materials</li>
          <li>Comprehension Exercises and Quizzes</li>
        </ul>

        {/* Module 6 */}
        <h4>Module 6</h4>
        <h5><strong>Reading and Writing Skills</strong></h5>
        <ul>
          <li>Reading Strategies for Different Texts</li>
          <li>Developing Writing Skills for Essays, Emails, and Reports</li>
          <li>Practice in Writing Clear and Coherent Texts</li>
          <li>Editing and Proofreading Techniques</li>
        </ul>

        {/* Module 7 */}
        <h4>Module 7</h4>
        <h5><strong>English for Specific Purposes (ESP)</strong></h5>
        <ul>
          <li>English for Business Communication</li>
          <li>English for Academic Purposes</li>
          <li>English for Travel and Hospitality</li>
          <li>English for Customer Service</li>
        </ul>
      </Element>
    </>
  );
};

export default EnglishCourseDetail;
