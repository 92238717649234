// PrivacyPolicy.js
import React from "react";


const PrivacyPolicy = () => {
  return (
    <div>
      <p>
        Ocupiie Hospitality Academy, we are committed to protecting your
        privacy and safeguarding your personal information. This Privacy Policy
        explains how we collect, use, and disclose the information of users and
        clients who interact with our academy, including students, faculty, and
        visitors.
      </p>
      <p>
        By using our services, visiting our website, or engaging with us in any
        capacity, you agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <p>
        <strong>
          We may collect the following personal information from our students,
          employees, and other individuals:
        </strong>
      </p>
      <ul>
        <li>Full name, contact information (email, phone number, address).</li>
        <li>Date of birth, gender, nationality, and language preferences.</li>
        <li>Educational background and language proficiency.</li>
        <li>
          Payment information (credit/debit card details, bank information).
        </li>
        <li>Employment history (for staff and faculty applications).</li>
      </ul>
      <p>
        <strong>Academic Information:</strong>
      </p>
      <ul>
        <li>
          Enrollment details, academic performance, assessments, and
          certifications.
        </li>
        <li>Attendance records and academic progress reports.</li>
      </ul>
      <p>
        <strong>Automatically Collected Information:</strong>
      </p>
      <ul>
        <li>IP address, browser type, and device information.</li>
        <li>
          Cookies, session data, and usage logs when interacting with our
          website or online learning platforms.
        </li>
      </ul>
      <p>
        <strong>Provision of Educational Services:</strong>
      </p>
      <ul>
        <li>
          To enroll you in courses, provide academic counseling, schedule
          classes, and track your progress in the language, hospitality, and
          professional skills training programs.
        </li>
        <li>
          To administer assessments, issue certificates, and provide
          personalized learning recommendations.
        </li>
      </ul>
      <p>
        <strong>Communication:</strong>
      </p>
      <ul>
        <li>
          To send you updates about courses, events, programs, and other related
          services.
        </li>
        <li>
          To communicate about your academic performance, attendance, and any
          changes to our programs or policies.
        </li>
      </ul>
      <p>
        <strong>Payment Processing:</strong>
      </p>
      <ul>
        <li>
          To process payments for course fees, examinations, certifications, and
          other services provided by Ocupiie Hospitality Academy.
        </li>
      </ul>
      <p>
        <strong>Improving Our Services:</strong>
      </p>
      <ul>
        <li>
          To enhance our training programs, learning platforms, and overall
          student experience based on feedback and usage data.
        </li>
      </ul>
      <p>
        <strong>Legal Compliance:</strong>
      </p>
      <ul>
        <li>
          To comply with legal obligations, protect the rights of Ocupiie
          Hospitality Academy, and resolve any disputes.
        </li>
      </ul>
      <p>
        <strong>Sharing and Disclosure of Information:</strong>
      </p>
      <p>
        We take your privacy seriously and will only share your information
        under the following circumstances:
      </p>
      <ul>
        <li>
          <strong>With Third-Party Service Providers:</strong> We may share
          personal information with trusted third parties who help us operate
          our business, such as payment processors, IT support, or marketing
          partners. These third parties are bound by confidentiality agreements
          and are only allowed to use your information as necessary to provide
          their services.
        </li>
        <li>
          <strong>With Partner Institutions:</strong> If you are enrolling
          through a partner institution or in collaboration with any of our
          academic or professional partners, we may share necessary academic
          information such as enrollment data, course results, and certification
          status for administrative purposes.
        </li>
      </ul>
     
       <p>
       <strong>Legal and Regulatory Authorities:</strong>
       </p>
       <p>
       We may disclose your
        information to law enforcement or regulatory bodies if required by law
        or in response to legal requests, such as subpoenas or government
        investigations.
        </p>
      <p>
        <strong>Business Transactions:</strong>
        </p>
         <p>In the event of a business
        transaction (e.g., a merger, acquisition, or asset sale), your personal
        information may be transferred to the new owners, provided they agree to
        uphold the terms of this Privacy Policy.
        </p>
     
      <p>
        <strong>Data Security:</strong>
      </p>
      <p>
        We implement strict security measures to protect your personal
        information. These include secure servers, encryption, and restricted
        access to sensitive data. Despite our efforts, no system is entirely
        secure, and we cannot guarantee the complete security of your
        information.
      </p>
      <p>
        <strong>Data Retention:</strong>
      </p>
      <p>
        We will retain your personal information for as long as it is necessary
        to provide our services, fulfill legal obligations, or resolve any
        disputes. After this period, your information will be securely deleted
        or anonymized.
      </p>
      <p>
        <strong>Your Rights:</strong>
      </p>
     
        <p>
          <strong>Access:</strong> Request access to the personal information we
          hold about you.
        </p>
        <p>
          <strong>Correction:</strong> Request corrections or updates to
          inaccurate or incomplete information.
        </p>
        <p>
          <strong>Deletion:</strong> Request deletion of your personal
          information, subject to certain exceptions (such as compliance with
          legal obligations).
        </p>
        <p>
          <strong>Data Portability:</strong> Request a copy of your data in a
          structured format that allows it to be transferred to another entity.
        </p>
        <p>
          <strong>Marketing Preferences:</strong> Opt out of marketing
          communications by using the unsubscribe option in our emails or by
          contacting us directly.
        </p>
     
      <p>
        To exercise any of these rights, please contact us at{" "}
        <a href="mailto:contact@ocupiie.com">contact@ocupiie.com</a>.
      </p>
      <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. When we make changes, we will
        update the "Effective Date" at the top of this page. We encourage you to
        review this Privacy Policy periodically to stay informed of any updates.
      </p>
    </div>
  );
};


export default PrivacyPolicy;




