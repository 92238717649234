import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Help.css';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import RefundPolicy from './RefundPolicy';


const Help = ({ onButtonClick }) => {


    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showRefund,setShowRefundPrivacy]=useState(false);


    const handleClosePrivacy = () => setShowPrivacy(false);
    const handleShowPrivacy = () => setShowPrivacy(true);

    const handalCloseRefundPrivacy=()=> setShowRefundPrivacy(false);
    const handalShowRefundPrivacy=()=> setShowRefundPrivacy(true);
    


    const handleCloseTerms = () => setShowTerms(false);
    const handleShowTerms = () => setShowTerms(true);


    return (
        <>
            <div className="navigation-links ">
                <a href="/" className="list-item">Home</a>
                <p className="list-item" onClick={onButtonClick}>About Us</p>
                <p className="list-item" onClick={handleShowPrivacy}> Privacy Policy</p>
                <p className="list-item" onClick={handleShowTerms}>Terms & Conditions</p>
                <p className='list-item' onClick={handalShowRefundPrivacy}>Refund Policy</p>
            </div>


            {/* Offcanvas for Privacy Policy */}
            <Offcanvas show={showPrivacy} onHide={handleClosePrivacy} placement="bottom" style={{ width: '50%',height: '80vh' }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Privacy Policy</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                   <PrivacyPolicy/>
                </Offcanvas.Body>
            </Offcanvas>


             {/* Offcanvas for Terms & Conditions */}
             <Offcanvas show={showTerms} onHide={handleCloseTerms} placement="bottom" style={{ width: '50%',height: '80vh'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Terms & Conditions</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <TermsAndConditions/>
                </Offcanvas.Body>
            </Offcanvas>
            {/* Offcanvas for Refund Policy */}
            <Offcanvas show={showRefund} onHide={handalCloseRefundPrivacy} placement='bottom' style={{width: '50%',height: '80vh'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><h2>Refund Policy</h2></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <RefundPolicy/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}


export default Help;


