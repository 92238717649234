import React, { useRef, useEffect } from 'react'

function ProfessionalGroomingandEtiquetteTrainingCourseDetails({ selectedSection }) {

    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("ProfessionalGroomingandEtiquetteTrainingCourseDetails");
        if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedSection]);


    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Professional Grooming and Etiquette Training</h2>
                <p>This course is designed to help participants
                    develop essential grooming and etiquette
                    skills, focusing on personal hygiene,
                    appearance, and professional demeanor. It
                    aims to equip learners with the tools to
                    present themselves confidently and
                    appropriately in various personal and
                    professional settings.


                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 4 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>2 days per week,</p>
                <p>2 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <><h4>Module 1</h4>
                    <h5><strong>Personal Hygiene and Self-Care</strong></h5>
                    <ul>
                        <li>Daily Hygiene Practices: Skin, Hair, and Oral Care</li>
                        <li>Understanding Different Skin Types and Hair Care
                            Needs</li>
                        <li>Importance of Regular Grooming: Nails, Haircuts,
                            and Skincare</li>
                        <li>Health and Wellness: Nutrition, Exercise, and Sleep</li>
                    </ul>
                </>


                <><h4>Module 2</h4>
                    <h5><strong>Dressing for Success</strong></h5>
                    <ul>
                        <li>Understanding Dress Codes: Formal, Business Casual,
                            and Casual</li>
                        <li>Choosing the Right Attire for Different Occasions</li>
                        <li>Importance of Fit, Color Coordination, and Style</li>
                        <li>Accessorizing Appropriately: Jewelry, Shoes, and Bags</li>
                        <li>Grooming for Different Professions (e.g., Corporate,
                            Hospitality)</li>
                    </ul>
                </>

                <><h4>Module 3</h4>
                    <h5><strong>Professional Etiquette</strong></h5>
                    <ul>
                        <li>Understanding Social Etiquette: Greetings,
                            Introductions, and Handshakes</li>
                        <li>Dining Etiquette: Table Manners and Proper Use of
                            Utensils</li>
                        <li>Workplace Etiquette: Communication, Email, and
                            Meeting Protocols</li>
                        <li>Telephone and Online Communication Etiquette</li>
                    </ul>
                </>

                <><h4>Module 4</h4>
                    <h5><strong>Body Language and First Impressions</strong></h5>
                    <ul>
                        <li>Importance of Positive Body Language: Posture, Gestures, and
                            Eye Contact</li>
                        <li>Reading and Responding to Others' Body Language</li>
                        <li>Creating a Positive First Impression</li>
                        <li>Building Confidence through a Polished Appearance</li>
                    </ul>
                </>

                <><h4>Module 5</h4>
                    <h5><strong>Grooming for Specific Situations</strong></h5>
                    <ul>
                        <li>Grooming for Interviews: Dress, Presentation, and
                            Confidence</li>
                        <li>Grooming for Public Speaking and Presentations</li>
                        <li>Grooming for Social Events and Networking</li>
                        <li>Seasonal Grooming: Adapting to Weather and Cultural
                            Expectations</li>
                    </ul>
                </>

                <><h4>Module 6</h4>
                    <h5><strong>Personal Branding</strong></h5>
                    <ul>
                        <li>Understanding Personal Branding and Its Importance</li>
                        <li>Developing a Consistent and Professional Image</li>
                        <li>Utilizing Social Media to Reflect Your Brand</li>
                        <li>Maintaining and Updating Your Personal Brand Over
                            Time</li>
                    </ul>
                </>

            </div>

        </>


    )
}

export default ProfessionalGroomingandEtiquetteTrainingCourseDetails