import React, { useState, useEffect } from "react";
import { load } from "@cashfreepayments/cashfree-js";
import {Button,Form,Container,Row, Col,
  Toast,
  FloatingLabel,
} from "react-bootstrap";
import axios from "axios"; // Make sure to import axios
const statesList = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Jammu and Kashmir",
];

const MultiStepForm = ({ selectedCourse }) => {
  const [cashfree, setCashfree] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSingle, setSelectedSingle] = useState("");
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    selectedOption: "",
    fullName: "",
    fatherName: "",
    address: "",
    city: "",
    state: "",
    mobile: "",
    email: "",
    emergencyContact: "",
    preferredClassTime: { hour: "", minute: "", period: "" },
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [totalAmount, setTotalAmount] = useState(0); // State for total amount

  useEffect(() => {
    // Initialize Cashfree SDK
    load({ mode: "production" })
        .then((cashfreeInstance) => {
            setCashfree(cashfreeInstance);
        })
        .catch((error) => console.error("Error loading Cashfree SDK:", error));
}, []);

  // Fetch total amount (including GST) when the selected course changes
  useEffect(() => {
    if (selectedCourse) {
      if (selectedSingle === "Single") {
        // For Single: subOption decides the total
        if (selectedOption === "oneTime") {
          const onetimeFees = selectedCourse.singleFee * 1.18;
          const singleFees = onetimeFees.toFixed(2);
          setTotalAmount(singleFees); // 100% of the course fee
        } else if (selectedOption === "installment") {
          const installmentFees = selectedCourse.singleFee * 0.6 * 1.18;
          const installmentFeess = installmentFees.toFixed(2);
          setTotalAmount(installmentFeess); // 60% of the course fee
        }
      }
      if (selectedOption === "Group") {
        const groupFees = selectedCourse.groupFee * 1.18;
        const groupFeess = groupFees.toFixed(2);
        setTotalAmount(groupFeess); // 100% for group
      }
    }
  }, [selectedCourse, selectedOption, selectedSingle]);

  // handale single
  const handleOptionSingal = (e) => {
    const selectedValue = e.target.value;
    setSelectedSingle(selectedValue);
    console.log(selectedValue);

    // Clear selectedOption if "Single" is selected
    if (selectedValue === "Single") {
      setSelectedOption(""); // Reset selectedOption
      setFormData({ ...formData, selectedOption: "" }); // Clear the formData selectedOption
    } else {
      setSelectedOption(""); // Also reset selectedOption if switching to Group
      setFormData({ ...formData, selectedOption: "" }); // Clear the formData selectedOption
    }
  };

  // Handle the option selection
  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);

    setFormData({ ...formData, selectedOption: option });
  };

  // Handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    if (name === "hour" || name === "minute") {
      const numericValue = Math.max(
        0,
        Math.min(parseInt(value, 10), name === "hour" ? 12 : 59)
      );

      setFormData((prevData) => ({
        ...prevData,
        preferredClassTime: {
          ...prevData.preferredClassTime,
          [name]: isNaN(numericValue)
            ? ""
            : numericValue.toString().padStart(2, "0"),
        },
      }));
    } else if (name === "period") {
      setFormData((prevData) => ({
        ...prevData,
        preferredClassTime: {
          ...prevData.preferredClassTime,
          period: value,
        },
      }));
    }
  };
  

  // Handle the Next button in step 1
  const handleNextClick = () => {
    if (!selectedOption) {
      setToastMessage("Please select an option to proceed.");
      setToastType("danger");
      setShowToast(true);
      return;
    }
    setFormData({ ...formData, selectedOption });
    setStep(2); // Move to step 2 after option selection
  };

  // Handle form submission for payment
  const handleSubmit = (e) => {
    e.preventDefault();
   
    const form = e.currentTarget;
    const isConfirmed = window.confirm(
      "Please review all fields carefully. Are you sure you want to proceed with the payment?"
    );
    if (!isConfirmed) {
      return; // Stop the submission process if the user clicks "Cancel"
    }
    if (!cashfree) {
      console.error("Cashfree SDK not initialized yet.");
      return;
  }

    const datasends = {
      FullName: formData.fullName,
      FatherName: formData.fatherName,
      Email: formData.email,
      Mobile: formData.mobile,
      EmergencyContact: formData.emergencyContact,
      Address: formData.address,
      State: formData.state,
      City: formData.city,
      PreferredClassTiming: `${formData.preferredClassTime.hour}:${formData.preferredClassTime.minute}`, // Format time correctly
      PreferredClassPeriod: `${formData.preferredClassTime.period}`,
      Course_Type: selectedOption,
      Course_Name: selectedCourse.name,
      Amount: totalAmount,
    };
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setToastMessage("Please fill in all required fields correctly.");
      setToastType("danger");
      setShowToast(true);
    } else {
      // console.log("Data to be sent:", datasends);
      //  axios.post("http://127.0.0.1:8000/api/pay", datasends)
      // .then((response) => {
      //     const paymentSessionId = response.data.payment_session_id;

      //     // Proceed with Cashfree checkout
      //     const checkoutOptions = {
      //         paymentSessionId: paymentSessionId,
      //         redirectTarget: "_modal",
      //     };

      //     cashfree.checkout(checkoutOptions)
      //         .then((result) => {
      //             if (result.error) {
      //                 console.log("User closed the popup or a payment error occurred");
      //                 console.log(result.error);
      //             } else if (result.redirect) {
      //                 console.log("Payment will be redirected");
      //             } else if (result.paymentDetails) {
      //                 console.log("Payment completed");
      //                 console.log(result.paymentDetails.paymentMessage);
      //             }
      //         })
      //         .catch((error) => console.error("Error during payment checkout:", error));
      // })
      // .catch((error) => console.error("Error sending form data:", error));

         axios.post("https://api.ocupiiehospitalityacademy.com/api/pay", datasends)
         //axios.post("http://127.0.0.1:8000/api/pay", datasends)
        
      .then((response) => {
          const paymentSessionId = response.data.payment_session_id;

          // Proceed with Cashfree checkout
          const checkoutOptions = {
              paymentSessionId: paymentSessionId,
              redirectTarget: "_model",
          };

          cashfree.checkout(checkoutOptions)
              .then((result) => {
                  console.log("Cashfree Checkout Result:", result); // Logs all details

                  if (result.error) {
                      console.log("User closed the popup or a payment error occurred");
                      console.log(result.error);
                  } 
                  if (result.redirect) {
                      console.log("Payment will be redirected");
                  } 
                  if (result.paymentDetails) {
                      console.log("Payment completed");
                      console.log("Payment Details:", result.paymentDetails);
                      console.log("Payment Message:", result.paymentDetails.paymentMessage);
                  }
              })
              .catch((error) => {
                console.error("Error during payment checkout:", error)
      });
            
      })
      .catch((error) => {
        console.error("Error sending form data:", error)
      });

    }
    setValidated(true); // Show validation feedback after submission
  };


  return (
    <Container className="mt-4">
      {showToast && (
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          className={`bg-${
            toastType === "success" ? "success" : "danger"
          } text-white`}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      )}

      {/* Step 1: Select Course Option */}
      {step === 1 && (
        <div className="my-3">
          <p>Select Type of Course</p>
          <Form.Check
            type="radio"
            id="option1"
            name="options"
            value="Single"
            label="Single"
            checked={selectedSingle === "Single"}
            onChange={handleOptionSingal}
            inline
          />
          <Form.Check
            type="radio"
            id="option2"
            name="options"
            value="Group"
            label="Group"
            checked={selectedOption === "Group"}
            onChange={handleOptionChange}
          />
          {selectedSingle === "Single" && (
            <Form.Select
              className="mt-2"
              style={{
                display: "inline-block",
                width: "auto",
                marginRight: "10px",
              }}
              onChange={handleOptionChange}
              aria-label="Single Options"
            >
              <option value="">Select One Time or Installment</option>
              <option value="oneTime">One Time</option>
              <option value="installment">Installment</option>
            </Form.Select>
          )}

          <Button onClick={handleNextClick} variant="primary" className="mt-3">
            Next
          </Button>
        </div>
      )}

      {/* Step 2: Fill out the form */}
      {step === 2 && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/* Display selected course in a read-only FloatingLabel input field */}
          <h3>
            <span style={{ fontFamily: "math-italic" }}>
              Fill the Application
            </span>
          </h3>
          {selectedCourse && (
            <FloatingLabel
              controlId="floatingSelectedCourse"
              label="Selected Course"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="selectedCourse"
                value={selectedCourse.name} // Assuming the course object has a 'name' property
                readOnly
              />
            </FloatingLabel>
          )}

          <FloatingLabel
            controlId="floatingSelectedOption"
            label="Selected Option"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="selectedOption"
              // value={formData.selectedOption}
              value={formData.selectedOption}
              readOnly
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingFullName"
            label="Full Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              placeholder="Full Name"
              autoFocus
              required
              pattern="[A-Za-z\s]{3,}" // Only accepts letters and spaces, minimum length of 3
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name(min.3 characters).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingFatherName"
            label="Father's Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="fatherName"
              value={formData.fatherName}
              onChange={handleInputChange}
              placeholder="Father's Name"
              required
              pattern="[A-Za-z\s]{3,}" // Only accepts letters and spaces, minimum length of 3
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid father's name(min.3 characters).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingMobile"
            label="Mobile Number"
            className="mb-3"
          >
            <Form.Control
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="Mobile Number"
              required
              pattern="[0-9]{10}" // Only accepts 10-digit numbers
              maxLength={10}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid mobile number(min.10 digit).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingEmail"
            label="Email"
            className="mb-3"
          >
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              // pattern="[\-a-zA-Z0-9~!$%^&amp;_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;_=+\}\{'?]+)@[a-zA-Z0-9_][\-a-zA-Z0-9_](\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingEmergencyContact"
            label="Emergency Contact"
            className="mb-3"
          >
            <Form.Control
              type="tel"
              name="emergencyContact"
              value={formData.emergencyContact}
              onChange={handleInputChange}
              placeholder="Emergency Contact"
              required
              pattern="[0-9]{10}" // Only accepts 10-digit numbers
              maxLength={10}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid emergency contact number(min.10 digit).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingAddress"
            label="Address"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Address"
              required
              minLength={10}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address( min. Length 10).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingState"
            label="State"
            className="mb-3"
          >
            <Form.Select
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
            >
              <option value="">Select State</option>
              {statesList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a state.
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId="floatingCity" label="City" className="mb-3">
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
              required
              pattern="[A-Za-z\s]{3,}" // Only accepts letters and spaces, minimum length of 3
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid city(minimum length of 3).
            </Form.Control.Feedback>
          </FloatingLabel>

          <p>Preferred Class Timing</p>
          <Row>
            <Col>
              <FloatingLabel
                controlId="floatingHour"
                label="Hour"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  name="hour"
                  value={formData.preferredClassTime.hour}
                  onChange={handleTimeChange}
                  required
                  min={1}
                  max={12}
                />
                <Form.Control.Feedback type="invalid">
                  Please select a valid hour (between 01 and 12).
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>

            <Col>
              <FloatingLabel
                controlId="floatingMinute"
                label="Minute"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  name="minute"
                  value={formData.preferredClassTime.minute}
                  onChange={handleTimeChange}
                  required
                  min={0}
                  max={59}
                />
                <Form.Control.Feedback type="invalid">
                  Please select a valid minute (between 00 and 59).
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>

            <Col>
              <FloatingLabel
                controlId="floatingPeriod"
                label="Period"
                className="mb-3"
              >
                <Form.Select
                  name="period"
                  value={formData.preferredClassTime.period}
                  onChange={handleTimeChange}
                  required
                >
                  <option value="">Choose...</option>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a period.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          {totalAmount > 0 && (
            <FloatingLabel
              controlId="floatingTotalAmount"
              label="Total Amount (including 18% GST):"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="totalAmount"
                value={`₹${totalAmount}`}
                readOnly
              />
            </FloatingLabel>
          )}

          <Button
            variant="secondary"
            onClick={() => {
              setStep(1);
              setSelectedSingle(""); // Reset selectedSingle
              setSelectedOption(""); // Reset selectedOption
              setFormData({ ...formData, selectedOption: "" }); // Clear formData selectedOption
            }}
            className="me-2"
          >
            Back
          </Button>

          <Button type="submit" variant="primary">
            Pay and Submit
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default MultiStepForm;