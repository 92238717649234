import React from 'react';
import { Container, Row, Col, Card,Image } from 'react-bootstrap';
import './HospitalityIndustriesPage.css'; // Custom CSS file for extra styling


const HospitalityCareerPage = () => {

  return (
    <>
      {/* Full-Width Image Section */}
      <div className="full-width-image">
            <Image
              src="/images/careerss.png" // Replace with your image URL
              alt="Hospitality Career"
               className="img-fluid"
            />
          </div>
    <Container className="my-4 ml-3 pl-4">
      <h2 className="text-center mb-4 h2clas">Hospitality Careers</h2>
      <p>The hospitality industry is one of the most dynamic and diverse sectors in the world, offering a wide range of career opportunities that cater to different interests, skills, and aspirations. Whether you’re passionate about creating unforgettable experiences for guests, managing high-stakes operations, or leading teams in a fast-paced environment, a career in hospitality can be incredibly rewarding. Here’s why pursuing a career in hospitality might be the perfect choice for you:</p>
      <Row className="gy-4">
        {/* Section 1 */}


        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg" >Diverse Career Paths</Card.Title>
            
                <p><strong>Hotels and Resorts:</strong> From front office management to guest relations and housekeeping, hotels and resorts offer a variety of roles focused on providing exceptional guest experiences.</p>
                <p><strong>Food and Beverage:</strong> Whether you're interested in managing restaurants, working as a chef, or overseeing food and beverage operations, this sector is full of opportunities.</p>
                <p><strong>Event Management:</strong> If you enjoy planning and executing events, a career in event management within the hospitality industry allows you to organize everything from weddings to corporate events.</p>
                <p><strong>Travel and Tourism:</strong> This sector offers roles in travel agencies, tour operations, and tourism management, catering to those who love to explore and share new experiences.</p>
                <p><strong>Cruise Lines:</strong> Working on a cruise ship can be a unique opportunity, offering roles in guest services, entertainment, food and beverage, and more, all while traveling the world.</p>
                <p><strong>Luxury and Boutique Services:</strong> For those interested in a more personalized and high-end service environment, luxury hotels, private clubs, and boutique establishments provide specialized career paths.</p>
            
            </Card.Body>
          </Card>
        </Col>
        {/* Section 2 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Global Opportunities</Card.Title>
              
                <p><strong>Work Anywhere in the World:</strong> Hospitality is a truly global industry, with opportunities to work in almost any country. Whether you dream of working in a bustling city, a tropical resort, or a historic European town, the hospitality industry can take you there.</p>
                <p><strong>Cultural Exchange:</strong> Working in hospitality allows you to interact with people from all over the world, making it a great career for those who enjoy cultural exchange and learning about different traditions.</p>
              
            </Card.Body>
          </Card>
        </Col>

        {/* Section 3 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Career Growth and Development</Card.Title>
              
                <p><strong>Rapid Advancement:</strong> The hospitality industry is known for offering rapid career progression, especially for those who are motivated and excel in customer service and management roles.</p>
                <p><strong>Continuous Learning:</strong> The industry is ever-evolving, with new trends and technologies emerging regularly. This offers endless opportunities for learning and professional development.</p>
                <p><strong>Leadership Opportunities:</strong> Many hospitality careers offer the chance to move into leadership roles, such as department manager, general manager, or even owning your own business.</p>
              
            </Card.Body>
          </Card>
        </Col>
        {/* Section 4 */}
        <Col md={9}>
          <Card className="no-border">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Interpersonal Skills Development</Card.Title>
              
                <p><strong>Customer Interaction:</strong> Hospitality careers are centered around providing excellent service, which means you’ll develop strong interpersonal and communication skills.</p>
                <p><strong>Teamwork:</strong> Working in hospitality often involves working closely with a team, enhancing your ability to collaborate and work effectively with others.</p>
                <p><strong>Problem-Solving:</strong> No two days are the same in hospitality, and you’ll often need to think on your feet to solve problems and ensure that guests have a positive experience.</p>
              
            </Card.Body>
          </Card>
        </Col>

        {/* Section 5 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Job Stability and Flexibility</Card.Title>
              
                <p><strong>High Demand:</strong> The hospitality industry is one of the largest employers globally, with a constant demand for skilled professionals in various roles.</p>
                <p><strong>Flexible Schedules:</strong> Many hospitality jobs offer flexible working hours, which can be ideal for those seeking work-life balance or needing a non-traditional work schedule.</p>
              
            </Card.Body>
          </Card>
        </Col>


        {/* Section 6 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Creative and Dynamic Work Environment</Card.Title>
            
                <p><strong>Innovation:</strong> Hospitality is an industry that thrives on creativity and innovation, whether it’s in developing new guest experiences, designing menus, or marketing travel destinations.</p>
                <p><strong>Exciting and Varied Work:</strong> With different guests, events, and situations every day, working in hospitality ensures that your job is never boring. It’s a field that keeps you engaged and challenged.</p>
              
            </Card.Body>
          </Card>
        </Col>

        {/* Section 7 */}
        <Col md={9}>
          <Card className="no-border">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Making a Positive Impact</Card.Title>
            
                <p><strong>Enhancing Experiences:</strong> In hospitality, you have the opportunity to create memorable experiences that can have a lasting impact on people’s lives, from celebrating special occasions to helping guests discover new places.</p>
                <p><strong>Community Contribution:</strong> Hospitality businesses often play a key role in local economies and communities, providing jobs and contributing to the local culture and tourism.</p>
              
            </Card.Body>
          </Card>
        </Col>





        {/* Section 8 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">Entrepreneurial Opportunities</Card.Title>
              
                <p><strong>Start Your Own Business:</strong> For those with an entrepreneurial spirit, the hospitality industry offers numerous opportunities to start your own business, whether it’s a restaurant, a boutique hotel, or a travel consultancy.</p>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
</>

  );
};


export default HospitalityCareerPage;




