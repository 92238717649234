import React from "react";
import { Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Slide.css';


const Slide = () => {
  return (
          <Carousel className="my-carousellll">
            <Carousel.Item interval={3000} className="carousel-item">
              <img
                 className="d-block w-100 img-fluid imglll "
                src="images/welcome.jpg"
                alt="Welcome"
              />
        
            </Carousel.Item>


            <Carousel.Item interval={3000}>
              <img
                 className="d-block w-100 img-fluid imglll"
                
                src="images/hospitalitys.png"
                alt="Hospitalitys"
              />
      
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                 className="d-block w-100 img-fluid imglll"
                src="images/learnlanguage.png"
                alt="Learnlanguage"
              />
          
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <img
                 className="d-block w-100 img-fluid imglll"
                src="images/softskill.png"
                alt="Soft Skill"
              />
          
            </Carousel.Item>
          </Carousel>
  );
};


export default Slide;





