import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './HospitalityIndustries.css'
import { Link } from 'react-router-dom';


function HospitalityCareer() {
  return (
    
      <div className="d-flex justify-content-around" >
          <Card style={{ width: '18rem', height: '360px' }} className='cardClasss'>
            <Card.Img variant="top"  src="images/careers.png" className='cardImagg' />
            <Card.Body className='cardCodyClass'>
              <Card.Title className='cardTitle'>Hospitality Career</Card.Title>
              <Card.Text className='cardText'>
              The hospitality industry is one of the most dynamic and diverse sectors in the world...
              </Card.Text>
              <Link to="/hospitality-career-page">
              <Button variant="success" className='cardbutton'>Know More</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>

      

  )
}


export default HospitalityCareer


