import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

const ComprehensiveSoftSkillsDevelopment = ({ selectedSection }) => {

  useEffect(() => {
    if (selectedSection) {
      // Scroll to the selected section with smooth behavior
      scroller.scrollTo(selectedSection, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [selectedSection]);

  return (
    <>
      {/* Course Overview Section */}
      <Element name="courseOverview" className="mb-4">
        <h2>Comprehensive Soft Skills Development</h2>
        <p>This course is designed to enhance participants' soft skills, including communication, teamwork, problem-solving, and adaptability. It aims to equip learners with essential interpersonal skills that are crucial for personal and professional success.</p>
      </Element>

      {/* Course Duration Section */}
      <Element name="duration" className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 8 weeks</p>
      </Element>

      {/* Sessions Section */}
      <Element name="sessions" className="mb-4">
        <h2>Sessions</h2>
        <p>2 days per week,</p>
        <p>2 hours per session</p>
      </Element>

      {/* Syllabus Section */}
      <Element name="syllabus" className="mb-4">
        <h2>Syllabus</h2>

        {/* Module 1 */}
        <h4>Module 1</h4>
        <h5><strong>Effective Communication</strong></h5>
        <ul>
          <li>Understanding Verbal and Non-Verbal Communication</li>
          <li>Active Listening and Empathy</li>
          <li>Assertive Communication and Negotiation Skills</li>
          <li>Public Speaking and Presentation Techniques</li>
        </ul>

        {/* Module 2 */}
        <h4>Module 2</h4>
        <h5><strong>Teamwork and Collaboration</strong></h5>
        <ul>
          <li>Building and Leading Effective Teams</li>
          <li>Understanding Team Roles and Dynamics</li>
          <li>Conflict Resolution and Mediation</li>
          <li>Collaborative Problem-Solving</li>
        </ul>

        {/* Module 3 */}
        <h4>Module 3</h4>
        <h5><strong>Problem-Solving and Decision-Making</strong></h5>
        <ul>
          <li>Critical Thinking and Analytical Skills</li>
          <li>Identifying and Defining Problems</li>
          <li>Generating and Evaluating Solutions</li>
          <li>Making Informed Decisions Under Pressure</li>
        </ul>

        {/* Module 4 */}
        <h4>Module 4</h4>
        <h5><strong>Adaptability and Flexibility</strong></h5>
        <ul>
          <li>Embracing Change and Uncertainty</li>
          <li>Developing a Growth Mindset</li>
          <li>Managing Stress and Building Resilience</li>
          <li>Time Management and Prioritization</li>
        </ul>

        {/* Module 5 */}
        <h4>Module 5</h4>
        <h5><strong>Emotional Intelligence (EQ)</strong></h5>
        <ul>
          <li>Understanding and Managing Emotions</li>
          <li>Building Self-Awareness and Self-Regulation</li>
          <li>Developing Social Awareness and Empathy</li>
          <li>Enhancing Interpersonal Relationships</li>
        </ul>

        {/* Module 6 */}
        <h4>Module 6</h4>
        <h5><strong>Professionalism and Work Ethic</strong></h5>
        <ul>
          <li>Building a Strong Professional Identity</li>
          <li>Ethical Decision-Making in the Workplace</li>
          <li>Time Management and Organizational Skills</li>
          <li>Cultivating a Positive Attitude and Professional Demeanor</li>
        </ul>

        {/* Module 7 */}
        <h4>Module 7</h4>
        <h5><strong>Leadership and Initiative</strong></h5>
        <ul>
          <li>Understanding Different Leadership Styles</li>
          <li>Developing Leadership Qualities and Taking Initiative</li>
          <li>Motivating and Inspiring Others</li>
          <li>Building Trust and Credibility as a Leader</li>
        </ul>

        {/* Module 8 */}
        <h4>Module 8</h4>
        <h5><strong>Continuous Personal Development</strong></h5>
        <ul>
          <li>Setting Personal and Professional Goals</li>
          <li>Lifelong Learning and Self-Improvement Strategies</li>
          <li>Building and Maintaining Healthy Habits</li>
          <li>Reflection and Continuous Growth</li>
        </ul>
      </Element>
    </>
  );
};

export default ComprehensiveSoftSkillsDevelopment;
