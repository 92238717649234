import React from "react";
import { Card, Container} from "react-bootstrap"; // Import Row and Col
import "./Coursesss.css";
import { Link } from "react-router-dom";

function CoursesSoftskill() {
  const cards = [
    {
      img: "images/communications.png",
      title: "Hospitality",
      text: "Learn about the hospitality industry and its growth potential.",
      link: "/courses/effectivecommunicationskillstraining",
    },
    {
      img: "images/groomings.png",
      title: "Soft Skills",
      text: "Develop crucial soft skills that will enhance your career.",
      link: "/courses/groomingandetiquette",
    },
    {
      img: "images/personalitys.png",
      title: "Languages",
      text: "Master new languages and expand your global communication skills.",
      link: "/courses/personalitydevelopmentandsoftskillstraining",
    },
    {
      img: "images/comprehensive.png",
      title: "Technology",
      text: "Stay updated with the latest advancements in technology.",
      link: "/courses/comprehensivesoftskillsdevelopment",
    },
  ];

  return (
    <Container fluid={true} className="m-0 p-0" style={{ padding: "0px", marginTop: "17px" }}>
      <h2 className="h2class">Soft Skills</h2>
      <div className="scroll-container">
        {cards.map((card, index) => (
          <Link to={card.link} className="card-link" key={index}>
            <Card className="cardClass">
              <Card.Img variant="top" src={card.img} alt={card.title} />
            </Card>
          </Link>
        ))}
      </div>
    </Container>
  );
}

export default CoursesSoftskill;
