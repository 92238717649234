import React, { useState } from 'react';
import './Student.css'; // Separate CSS for Student Links if needed
import Modal from 'react-modal';
import { Container, Row, Col } from 'react-bootstrap'; // Import necessary Bootstrap components

// Modal custom styles (optional)
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark overlay with high transparency
        zIndex: 1000, // Set z-index for the overlay
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Bind modal to your app element (accessibility)
Modal.setAppElement('#root');

const Student = () => {
    // State for modals visibility
    const [isEnrollModalOpen, setIsEnrollModalOpen] = useState(false);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

    // Functions to open and close the "Check Enrollment" modal
    const openEnrollModal = () => setIsEnrollModalOpen(true);
    const closeEnrollModal = () => setIsEnrollModalOpen(false);

    // Functions to open and close the "Verify Certificate" modal
    const openVerifyModal = () => setIsVerifyModalOpen(true);
    const closeVerifyModal = () => setIsVerifyModalOpen(false);

    return (
        <Container className="student-links text-center">
            <Row className="justify-content-center">
                <Col xs={"auto"}> {/* Center the content in the column */}
                    <h5>For Students</h5> {/* Main label "For Students" */}
                    <p>
                        <a href="https://drive.google.com/file/d/1nF_GpzVtZD0sjrpIXIBVFQkGJl2_o9jt/view?usp=sharing" className="student-link">
                            Download Your Prospectus
                        </a>
                    </p>
                    <p className="button-as-linkss" onClick={openEnrollModal}>
                        Check Your Enrollment
                    </p>
                    <p className="button-as-linkss" onClick={openVerifyModal}>
                        Verify Your Certificate
                    </p>

                    {/* Modal for "Check Your Enrollment" */}
                    <Modal
                        isOpen={isEnrollModalOpen}
                        onRequestClose={closeEnrollModal}
                        style={customStyles}
                        contentLabel="Check Enrollment"
                    >
                        <h2>Check Your Enrollment</h2>
                        <form style={{ width: "500px" }}>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth:</label>
                                <input type="date" id="dob" name="dob" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="enrollmentNo">Enrollment Number:</label>
                                <input type="text" id="enrollmentNo" name="enrollmentNo" required />
                            </div>
                            <div className="form-actions">
                                <button type="submit">Submit</button>
                                <button type="button" onClick={closeEnrollModal}>Close</button>
                            </div>
                        </form>
                    </Modal>

                    {/* Modal for "Verify Your Certificate" */}
                    <Modal
                        isOpen={isVerifyModalOpen}
                        onRequestClose={closeVerifyModal}
                        style={customStyles}
                        contentLabel="Verify Certificate"
                    >
                        <h2>Verify Your Certificate</h2>
                        <form style={{ width: "500px" }}>
                            <div className="form-group">
                                <label htmlFor="enrollmentNo">Enrollment Number:</label>
                                <input type="text" id="enrollmentNo" name="enrollmentNo" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth:</label>
                                <input type="date" id="dob" name="dob" required />
                            </div>
                            <div className="form-actions">
                                <button type="submit">Submit</button>
                                <button type="button" onClick={closeVerifyModal}>Close</button>
                            </div>
                        </form>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
}

export default Student;
