import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState } from 'react';
import './Hospitality.css';
import { Link } from 'react-router-dom';

function Hospitality() {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  return (
    <Dropdown
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={show} // Controls the visibility of the dropdown
      key='end'
      drop='end'
      variant="secondary"
    //   title={` Drop end `}

    >
      <Dropdown.Toggle variant="success" id="dropdown-basic">
      Hospitality
      </Dropdown.Toggle>

      <Dropdown.Menu className='Dropdown-Menu'
      >
        <Dropdown.Item as={Link} to="/courses/foodandbeveragemanagementtraining" >Food and Beverage Management Training</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/foodindustrymanagementandoperations">Food Industry Management and Operation</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/frontofficemanagementtraining">Front Office Management Training</Dropdown.Item>
        <Dropdown.Item  as={Link} to="/courses/hotelmarketingstrategiestraining">Hotel Marketing Strategies Training</Dropdown.Item>
        
        <Dropdown.Item as={Link} to="/courses/kitchenoperationstraining">Kitchen Oprations Training</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/masteringhotelfinancials">Mastering Hotel Financials</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/restaurantmanagementtraining" >Restaurant Management Training</Dropdown.Item>
        <Dropdown.Item as={Link} to="/courses/roomserviceskillstraining">Room Service Skill Training</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Hospitality;
