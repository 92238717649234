import React from 'react';
import stylesss from './RefundPolicy.module.css';

const  RefundPolicy= ()=> {

  return (
    <div className={stylesss.refundPolicy}>
    
      <p>
        At <strong>Ocupiie Hospitality Academy</strong>, we are committed to providing the best learning experience for our students. However, we understand that circumstances may arise where a refund is necessary. Please read our refund policy carefully before purchasing any of our online language courses or hospitality training programs.
      </p>
      <hr />

      <ol>
        <li><strong>Eligibility for Refunds</strong>
          <ol>
            <li><strong>Refund Requests</strong> must be submitted within <strong>one day</strong> from the date of purchase.</li>
            <li>Refunds will only be granted if you have <strong>not accessed</strong> the course materials (videos, reading content, downloadable resources, etc.).</li>
            <li>No refunds will be provided once any part of the course materials has been accessed or downloaded.</li>
          </ol>
        </li>

        <li><strong>Non-Refundable Situations</strong>
          <p>Refunds will not be provided in the following situations:</p>
          <ol>
            <li>If you have accessed the course content or attended any live sessions.</li>
            <li>If the refund request is made after <strong>one day</strong> of the purchase date.</li>
            <li>If technical issues arise due to the student’s device, internet connection, or system requirements that were not met.</li>
            <li>For promotional offers, discounts, or courses purchased at a reduced rate.</li>
          </ol>
        </li>

        <li><strong>Course Cancellation or Rescheduling</strong>
          <ol>
            <li><strong>Ocupiie Hospitality Academy</strong> reserves the right to cancel or reschedule any course.</li>
            <li>If we cancel or reschedule a course, you will be offered a choice of a full refund or the option to transfer to a future course date without additional cost.</li>
            <li>In the event of rescheduling, you may request a refund if the new date does not suit your availability.</li>
          </ol>
        </li>

        <li><strong>How to Request a Refund</strong>
          <ol>
            <li>To request a refund, please contact us at <a href="mailto:contact@ocupiie.com">contact@ocupiie.com</a> with your full name, course details, and the reason for your refund request.</li>
            <li>Our team will review your request and, if eligible, process the refund within <strong>seven business days</strong> of approval.</li>
            <li>Refunds will be processed using the original payment method. Processing times may vary depending on your payment provider.</li>
          </ol>
        </li>

        <li><strong>Partial Refunds</strong>
          <ol>
            <li>Ocupiie may, at its sole discretion, offer partial refunds in certain cases where part of the course materials was accessed due to technical errors or other extraordinary circumstances.</li>
          </ol>
        </li>

        <li><strong>Payment Gateway Fees</strong>
          <ol>
            <li>Any payment gateway transaction fees incurred during the purchase may be non-refundable depending on the payment provider’s policy.</li>
          </ol>
        </li>

        <li><strong>Contact Information</strong>
          <p>
            For any queries regarding refunds or to submit a refund request, please contact:<br />
            <strong>Ocupiie Hospitality Academy</strong> <br />
            <a href="mailto:contact@ocupiie.com">contact@ocupiie.com</a>
          </p>
        </li>
      </ol>
    </div>
  );
}

export default RefundPolicy;