// ContactInfo.js
import React from "react";
import "./Contact.css"; // Separate CSS for ContactInfo
import { Container, Row,Col } from "react-bootstrap";

const Contact = () => {
  return (
    <Container className="contact-info text-center m-0 p-0" >
      <Row className="justify-content-center p-0 m-0">
        <Col xs={"auto"} className="p-0 m-0">
          {" "}
          {/* Center the content in the column */}
          <h5>Contact Us</h5> {/* Contact Us label */}
          <p>
            <strong>Phone:</strong>{" "}<br/>
            <a href="tel:+91 36140 52618" className="contact-link">
              +91 36140 52618
            </a>
          </p>
          <p>
            <strong>Email:</strong>{" "}<br/>
            <a href="mailto:contact@ocupiie.com" className="contact-links">
              contact@ocupiie.com
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
