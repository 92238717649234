import React, { useRef, useEffect } from 'react';


const SpanishCourseDetails = ({ selectedSection }) => {
  const courseOverviewRef = useRef(null);
  const syllabusRef = useRef(null);
  const durationRef = useRef(null);
  const sessionsRef = useRef(null);

  useEffect(() => {
    console.log("spanish")
    if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedSection]);


  return (
    <>
      <div ref={courseOverviewRef} className="mb-4">
        <h2>Spanish Course Overview</h2>
        <p> This course is designed to help participants
 develop their Spanish language skills,
 focusing on speaking, listening, reading, and
 writing. The course is suitable for learners at
 various proficiency levels, aiming to improve
 their communication skills for personal,
 academic, or professional purposes.
</p>
      </div>
      
      <div ref={durationRef} className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 8 weeks</p>
      </div>


      <div ref={sessionsRef} className="mb-4">
        <h2>Sessions</h2>
        <p>3 days per week,</p>
        <p>1.5 hours per session</p>
      </div>
      <div ref={syllabusRef} className="mb-4">
        <h2>Syllabus</h2>
        <h4>Module 1</h4>
           <h5><strong>Introduction to Spanish Language</strong></h5>    
           <> 
           <ul>
            <li>Spanish Alphabet and Pronunciation</li>
            <li>Basic Greetings and Introductions</li>
            <li>Numbers, Days of the Week, and Months</li>
            <li>Simple Questions and Answers</li>
        </ul>
        </>

        <h4>Module 2</h4>
           <h5><strong>Everyday Vocabulary and Phrases</strong></h5>    
          <>  <ul>
            <li>Common Nouns, Verbs, and Adjectives</li>
            <li>Talking About Family, Friends, and Occupations</li>
            <li>Describing Daily Routines and Activities</li>
            <li>Shopping and Ordering Food in Spanish</li>
        </ul>
        </>
        
        <h4>Module 3</h4>
           <h5><strong>Basic Grammar</strong></h5>    
           <> <ul>
            <li>Introduction to Spanish Sentence Structure</li>
            <li>Present Tense of Regular and Irregular Verbs</li>
            <li>Gender and Number Agreement in Nouns and
            Adjectives</li>
            <li>Forming Questions and Negatives</li>
        </ul>
        </>
        
        <h4>Module 4</h4>
           <h5><strong>Conversational Spanish</strong></h5>    
           <>  <ul>
            <li>Engaging in Basic Conversations: Asking for Directions, Making
            Plans</li>
            <li>Talking About Hobbies, Interests, and Leisure Activities</li>
            <li>Discussing Weather, Time, and Dates</li>
            <li>Role-Playing Common Situations: At the Restaurant, Hotel, and
            Market </li>
        </ul>
        </>
         
        <h4>Module 5</h4>
           <h5><strong>Reading and Writing</strong></h5>    
           <>  <ul>
            <li>Reading Simple Texts: Short Stories, Dialogues, and
            Articles</li>
            <li>Writing Basic Sentences and Paragraphs</li>
            <li>Composing Emails, Letters, and Messages in Spanish</li>
            <li>Introduction to Spanish Literature and Poetry</li>
        </ul>
        </>
        
        <h4>Module 6</h4>
           <h5><strong>Listening and Comprehension</strong></h5>    
           <>   <ul>
            <li>Listening to Native Spanish Speakers: Audio Clips,
            Songs, and Videos</li>
            <li>Comprehending Spoken Spanish in Various Contexts</li>
            <li>Practicing Listening Skills Through Interactive Exercises</li>
            <li>Understanding Accents and Regional Variations</li>
        </ul>
        </>
 
        <h4>Module 7</h4>
           <h5><strong>Spanish Culture and Customs</strong></h5>    
           <>    <ul>
            <li>Introduction to Spanish-Speaking Countries and
            Cultures</li>
            <li>Understanding Social Etiquette and Traditions</li>
            <li>Exploring Spanish Festivals, Music, and Cuisine cultural Differences and Similarities</li>
        </ul>
        </>
      </div> 
      
    </>
  );
};


export default SpanishCourseDetails;





