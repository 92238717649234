import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './HospitalityIndustries.css'
import { Link } from 'react-router-dom';


function HospitalityGuidence() {
  return (
        <div className="d-flex justify-content-around" >
          <Card style={{ width: '18rem', height: '360px' }} className='cardClasss'>
            <Card.Img variant="top" src="images/careerguide.png" className='cardImagg' />
            <Card.Body className='cardCodyClass'>
              <Card.Title className='cardTitle'>Career Guidence</Card.Title>
              <Card.Text className='cardText'>
              Our Career Guidance Program
               At Ocupiie Hospitality Academy, we are dedicated not only to providing...
              </Card.Text>
              <Link to="/hospitality-guidence-career-page">
              <Button variant="success" className='cardbutton'>Know More</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
      );
   
}


export default HospitalityGuidence
