import { NavLink } from 'react-router-dom'; // Import NavLink for active state
import React, { useState } from 'react';
import './CourseListColor.css' // Make sure your CSS is updated with the styles below
import { Button, Col, Container, ListGroup, Row, Collapse } from 'react-bootstrap';

const CourseList = ({ courses, setSelectedCourse, setSelectedSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCourseList = () => setIsOpen(!isOpen);

  return (
    <div>
      <Container>
        <h3 className="d-block d-md-none">Ocupiie Hospitality Academy</h3> {/* Heading visible on small screens */}
        <Row>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('courseOverview')}>Overview</Button>
          </Col>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('syllabus')}>Syllabus</Button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('sessions')}>Sessions</Button>
          </Col>
          <Col className="d-flex flex-column gap-3">
            <Button variant="outline-primary" className='sectionButton' onClick={() => setSelectedSection('duration')}>Duration</Button>
          </Col>
        </Row>
      </Container>

      {/* Toggle button with red text and custom spacing on small screens */}
      <Button
        onClick={toggleCourseList}
        aria-controls="course-list-collapse"
        aria-expanded={isOpen}
        className="toggle-course-btn d-md-none mt-3" // Add custom class
      >
        {isOpen ? 'Hide Courses' : 'Show Courses'}
      </Button>

      {/* Collapsible course list for small screens */}
      <Collapse in={isOpen} className="d-md-none">
        <div id="course-list-collapse">
          <ListGroup>
            {courses.map(course => (
              <ListGroup.Item key={course.id} action className='custom-list-item'>
                <NavLink
                  to={`/courses/${course.id}`}
                  onClick={() => {
                    setSelectedCourse(course.id);
                    setSelectedSection(null); // Reset section scrolling
                    setIsOpen(false); // Close the list automatically on selection
                  }}
                  className={({ isActive }) => isActive ? 'active-course-link' : ''} // Apply active class
                  style={{ textDecoration: 'none', color: 'inherit', display: 'block', width: '100%' }} // Make NavLink take full width
                >
                  {course.name}
                </NavLink>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </Collapse>

      {/* Full course list for larger screens */}
      <div className="d-none d-md-block">
        <ListGroup>
          {courses.map(course => (
            <ListGroup.Item key={course.id} action className='custom-list-item'>
              <NavLink
                to={`/courses/${course.id}`}
                onClick={() => {
                  setSelectedCourse(course.id);
                  setSelectedSection(null); // Reset section scrolling
                }}
                className={({ isActive }) => isActive ? 'active-course-link' : ''} // Apply active class
                style={{ textDecoration: 'none', color: 'inherit', display: 'block', width: '100%' }} // Make NavLink take full width
              >
                {course.name}
              </NavLink>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </div>
  );
};

export default CourseList;
