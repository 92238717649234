import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import React from 'react';
import './MissionVision.css';

function MissionVision() {
  return (
    <>
      <Container fluid={true} className=' p-4 m-0'>
      <Row className='corner-border-card'>
        <Row id="mission-section"> {/* Add id to this row for smooth scroll */}
          <Col md={3} className='strongOurs'><strong >Our</strong> Mission</Col>
          <Col md={9} className='strongOurss'>
            <p> Our mission at<strong> Ocupiie Hospitality Academy </strong>is to provide high-quality education
              and training that prepares individuals for rewarding careers in the hospitality industry.<br />
              We aim to:<br /><strong> Equip students</strong> with the skills, knowledge, and practical experience needed
              to excel in the global hospitality sector.
              <br /><strong>Empower communities </strong>by offering access to professional development
              opportunities and creating a highly skilled workforce.
              <br /><strong>Bridge the gap</strong> between employers and job seekers by offering recruitment
              solutions that meet the needs of the industry.
              <br /><strong>Foster growth and innovation</strong> in the hospitality industry through continuous
              learning, training, and excellence in service.
            </p>
          </Col>
        </Row>
        <Row id="vision-section"> {/* Add id to this row for smooth scroll */}
          <Col md={3} className='strongOurs'> <div><strong>Our </strong>Vision </div> </Col>
          <Col md={9}>
            <p>Our vision is to become a <strong>global leader in hospitality education and training</strong>,
              known for producing professionals who set the standard for excellence in the
              industry. <br />We aspire to:
              <br />Expand our reach to more regions and countries, creating a network of top-
              tier hospitality professionals.
              <br />Continuously innovate our training programs to meet the changing demands
              of the hospitality sector.<br />Be a trusted partner for students, employers, and industry leaders in
              developing talent and fostering long-term career success.
              <br />Promote <strong>sustainability and diversity </strong>within the hospitality industry, ensuring
              that our graduates are well-rounded, ethical professionals capable of making
              a positive impact globally.<br />
              At <strong>Ocupiie Hospitality Academy</strong>, we are committed to transforming education and
              shaping the future of hospitality with passion, dedication, and excellence.
            </p>
          </Col>
        </Row>
        </Row>
      </Container>
    </>
  );
}

export default MissionVision;
