// TermsAndConditions.js
import React from 'react';


const TermsAndConditions = () => {
    return (
        <div>
           
            <p>
            Welcome to <strong>Ocupiie Hospitality Academy</strong>.By accessing or using our website, services, courses, or any other offering provided by the academy, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully before enrolling or using any of our services. If you do not agree to these terms, you should refrain from using our services.
            </p>
            <h6><strong>Acceptance of Terms</strong></h6>
            <p>
                By using the services provided by Ocupiie Hospitality Academy, including enrolling in courses, participating in online or offline training, or accessing our website, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
            </p>
            <p>
                Ocupiie Hospitality Academy reserves the right to update, change, or replace any part of these Terms and Conditions at any time without prior notice. The latest version of these terms will always be available on our website.
            </p>
            <h6><strong>Services Provided</strong></h6>
            <p>Ocupiie Hospitality Academy provides a variety of services, including but not limited to:</p>
            <ul>
                <li>Language Training (online and in-person)</li>
                <li>Hospitality and Skill Development Courses</li>
                <li>Workshops and Certifications</li>
                <li>Student Counseling and Career Guidance</li>
                <li>Recruitment and Placement Services</li>
            </ul>
            <p>All courses, certifications, and services are subject to availability and may change or be modified at any time.</p>
            <h6><strong>Eligibility</strong></h6>
            <p>To be eligible to enroll in our courses or use our services, you must:</p>
            <ul>
                <li>Be at least 18 years old (or have parental consent if younger).</li>
                <li>Provide accurate and complete information during registration or enrollment.</li>
                <li>Agree to follow the rules, guidelines, and academic policies set by the academy.</li>
            </ul>
            <p>Ocupiie Hospitality Academy reserves the right to deny enrollment or revoke access to its services if false information is provided or the individual violates these terms.</p>
            <h6><strong>Registration and Account Security</strong></h6>
            <p>
                <strong>a. Account Creation:</strong> To access certain services, you may need to create an account on our platform. You are responsible for maintaining the confidentiality of your account credentials, and any activity under your account is your responsibility.
            </p>
            <p>
                <strong>b. Accuracy of Information:</strong> You agree to provide true, accurate, and current information during registration or course enrollment. Ocupiie Hospitality Academy will not be held responsible for any issues arising from inaccurate or incomplete information.
            </p>
            <p>
                <strong>c. Account Termination:</strong> We reserve the right to suspend or terminate your account or access to any services if you violate any of these Terms and Conditions.
            </p>
            <h6><strong>Payment and Fees</strong></h6>
            <p>
                <strong>a. Course Fees:</strong> All fees for courses, training, or services must be paid in full before the start of the program unless otherwise stated. Payment details, including due dates and payment methods, will be provided during enrollment.
            </p>
            <p>
                <strong>b. Refund Policy:</strong> Refunds are governed by our refund policy. Generally, refunds may be granted if requested within a specified time frame before the course starts. However, fees for services or courses already started are non-refundable unless otherwise specified. Contact us for specific refund inquiries.
            </p>
            <p>
                <strong>c. Late Payments:</strong> Failure to make timely payments may result in suspension or removal from the course or service.
            </p>
            <h6><strong>Course Enrollment and Participation</strong></h6>
            <p>
                <strong>a. Enrollment Confirmation:</strong> Enrollment in any course or program is confirmed only after full payment has been received and an official confirmation is issued by Ocupiie Hospitality Academy.
            </p>
            <p>
                <strong>b. Course Materials:</strong> All course materials, including lecture notes, videos, and other resources, are provided solely for personal use by enrolled students. You may not reproduce, distribute, or sell any course materials without prior permission.
            </p>
            <p>
                <strong>c. Attendance and Completion:</strong> Students are expected to attend all scheduled classes, whether online or in-person, and to complete assignments and assessments as required. Failure to meet attendance or academic requirements may result in removal from the course without refund.
            </p>
            <p>
                <strong>d. Certification:</strong> Certification will be awarded only upon successful completion of the course and fulfillment of all academic requirements, including assessments, exams, and project submissions, if applicable.
            </p>
            <h6><strong>User Conduct</strong></h6>
            <p>By accessing our services or courses, you agree to:</p>
            <ul>
                <li>Abide by all applicable laws and regulations.</li>
                <li>Respect the rights of other users, staff, and faculty.</li>
                <li>Refrain from using offensive, discriminatory, or inappropriate language in any interactions.</li>
                <li>Avoid any disruptive behavior in both physical and online environments.</li>
            </ul>
            <p>Ocupiie Hospitality Academy has the right to suspend or remove any user from courses or platforms who violates these rules or engages in unethical behavior.</p>
            <h6><strong>Intellectual Property</strong></h6>
            <p>
                <strong>a. Ownership:</strong> All content, materials, logos, and trademarks on our website or in our courses are the intellectual property of Ocupiie Hospitality Academy or its licensors. You may not copy, reproduce, or distribute any materials without express permission.
            </p>
            <p>
                <strong>b. Use of Materials:</strong> Students and users are granted a non-exclusive, non-transferable license to access and use course materials solely for personal, educational purposes. Any unauthorized use may result in legal action.
            </p>
            <h6><strong>Limitation of Liability</strong></h6>
            <p>Ocupiie Hospitality Academy is not liable for any indirect, incidental, or consequential damages arising from:</p>
            <ul>
                <li>Your use or inability to use our services.</li>
                <li>Any errors or omissions in our courses, content, or services.</li>
                <li>Unauthorized access to or alteration of your account.</li>
            </ul>
            <p>Our maximum liability to any user will not exceed the amount paid by that user for the specific service or course.</p>
            <h6><strong>Cancellation and Termination</strong></h6>
            <p>
                <strong>a. Cancellation by Student:</strong> If you wish to cancel your enrollment in a course or service, you must notify us in writing. Refunds, if applicable, will be processed according to our refund policy.
            </p>
            <p>
                <strong>b. Termination by Ocupiie Hospitality Academy:</strong> We reserve the right to terminate or suspend any user’s access to our services for violations of these terms, non-payment, or any inappropriate conduct.
            </p>
            <h6><strong>Privacy Policy</strong></h6>
            <p>Your privacy is important to us. Please refer to our Privacy Policy for detailed information about how we collect, use, and protect your personal information.</p>
            <h6><strong>Governing Law</strong></h6>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of Assam, India. Any disputes arising out of or in connection with these terms will be subject to the exclusive jurisdiction of the courts in Assam, India.</p>
            <h6><strong>Amendments</strong></h6>
            <p>Ocupiie Hospitality Academy reserves the right to update or amend these Terms and Conditions at any time. Continued use of our services after any such changes constitutes acceptance of the new terms.</p>
        </div>
    );
};


export default TermsAndConditions;
