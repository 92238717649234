import React, { useRef, useEffect } from 'react';

const FrenchCourseDetails = ({ selectedSection }) => {
  const courseOverviewRef = useRef(null);
  const syllabusRef = useRef(null);
  const durationRef = useRef(null);
  const sessionsRef = useRef(null);


  useEffect(() => {
    console.log("french");
    if (selectedSection === 'courseOverview') courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'syllabus') syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'duration') durationRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (selectedSection === 'sessions') sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedSection]);


  return (
    <>
    <div ref={courseOverviewRef} className="mb-4">
        <h2>French Course Overview</h2>
        <p>This course is designed to help participants
 develop their French language skills, focusing
 on speaking, listening, reading, and writing.
 The course is suitable for learners at various
 proficiency levels, aiming to improve their
 communication skills for personal, academic,
 or professional purposes.
</p>
      </div>
      
      <div ref={durationRef} className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 12 weeks</p>
      </div>

      <div ref={sessionsRef} className="mb-4">
        <h2>Sessions</h2>
        <p>3 days per week,</p>
        <p>2 hours per session</p>
      </div>
      <div ref={syllabusRef} className="mb-4">
        <h2>Syllabus</h2>
        <><h4>Module 1</h4>
           <h5><strong>Introduction to French Language</strong></h5>    
            <ul>
            <li> Overview of the French Language and its Global
            Influence</li>
            <li> Assessing Current Proficiency Levels</li>
            <li>The French Alphabet and Pronunciation Basics</li>
        </ul>
        </>
        


    
        <><h4>Module 2</h4>
           <h5><strong>French Grammar and Sentence Structure</strong></h5>    
            <ul>
            <li>Basic Grammar Rules and Sentence Construction</li>
            <li>Nouns, Verbs, Adjectives, and their Conjugations</li>
            <li>Understanding Tenses and Moods</li>
            <li>Common Grammatical Mistakes and How to Avoid
            Them</li>
        </ul>
        </>

        <><h4>Module 3</h4>
           <h5><strong>Vocabulary Development</strong></h5>    
            <ul>
            <li>Building a Basic French Vocabulary</li>
            <li>Common Idiomatic Expressions and Phrases</li>
            <li>Vocabulary for Specific Situations (e.g., Travel,
              Business)</li>
            <li>Techniques for Memorizing New Words</li>
        </ul>
        </>
      
        <><h4>Module 4</h4>
           <h5><strong>Speaking and Pronunciation</strong></h5>    
            <ul>
            <li>Improving Pronunciation and Intonation in French</li>
            <li>Practice in Conversational French</li>
            <li> Role-Playing Scenarios for Daily Life and Work</li>
            <li>Public Speaking and Presentation Skills in French</li>
        </ul>
        </>

        <><h4>Module 5</h4>
           <h5><strong>Listening and Comprehension</strong></h5>    
            <ul>
            <li>Strategies for Understanding Spoken French</li>
            <li> Listening to Different French Accents</li>
            <li> Audio and Video Practice Materials</li>
            <li> Comprehension Quizzes and Exercises</li>
        </ul>
        </>
        
        <><h4>Module 6</h4>
           <h5><strong>Reading and Writing Skills</strong></h5>    
            <ul>
            <li>Reading Strategies for French Texts (Articles, Books,
              etc.)</li>
            <li> Writing Skills for Essays, Emails, and Formal Documents</li>
            <li> Practice in Writing Clear and Coherent French Texts</li>
            <li>Editing and Proofreading Techniques</li>
        </ul>
        </>
      
        
        <><h4>Module 7</h4>
           <h5><strong>French for Specific Purposes (FSP)</strong></h5>    
            <ul>
            <li>French for Business Communication</li>
            <li> French for Academic Purposes</li>
            <li>French for Travel and Hospitality</li>
            <li>French for Customer Service</li>
        </ul>
        </>
      </div> 


     
    </>
  );
};


export default FrenchCourseDetails;
