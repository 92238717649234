import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

const ArabicCourseDetails = ({ selectedSection }) => {

  useEffect(() => {
    if (selectedSection) {
      // Scroll to the selected section with smooth behavior
      scroller.scrollTo(selectedSection, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [selectedSection]);

  return (
    <>
      {/* Course Overview Section */}
      <Element name="courseOverview" className="mb-4">
        <h2>Arabic Course Overview</h2>
        <p>This course is designed to help participants develop their Arabic language skills, focusing on speaking, listening, reading, and writing. The course is suitable for learners at various proficiency levels, aiming to improve their communication skills for personal, academic, or professional purposes.</p>
      </Element>

      {/* Course Duration Section */}
      <Element name="duration" className="mb-4">
        <h2>Course Duration</h2>
        <p><strong>Total Duration:</strong> 12 weeks</p>
      </Element>

      {/* Sessions Section */}
      <Element name="sessions" className="mb-4">
        <h2>Sessions</h2>
        <p>3 days per week,</p>
        <p>2 hours per session</p>
      </Element>

      {/* Syllabus Section */}
      <Element name="syllabus" className="mb-4">
        <h2>Syllabus</h2>

        {/* Module 1 */}
        <h4>Module 1</h4>
        <h5><strong>Introduction to Arabic Language</strong></h5>
        <ul>
          <li>Overview of the Arabic Language and its Importance</li>
          <li>Arabic Script and Pronunciation Basics</li>
          <li>Assessing Current Proficiency Levels</li>
        </ul>

        {/* Module 2 */}
        <h4>Module 2</h4>
        <h5><strong>Arabic Grammar and Sentence Structure</strong></h5>
        <ul>
          <li>Basic Grammar Rules and Sentence Construction</li>
          <li>Nouns, Verbs, Adjectives, and their Conjugations</li>
          <li>Understanding Tenses and Sentence Structures</li>
          <li>Common Grammatical Mistakes and How to Avoid Them</li>
        </ul>

        {/* Module 3 */}
        <h4>Module 3</h4>
        <h5><strong>Vocabulary Development</strong></h5>
        <ul>
          <li>Building a Basic Arabic Vocabulary</li>
          <li>Common Idiomatic Expressions and Phrases</li>
          <li>Vocabulary for Specific Situations (e.g., Travel, Business)</li>
          <li>Techniques for Memorizing New Words</li>
        </ul>

        {/* Module 4 */}
        <h4>Module 4</h4>
        <h5><strong>Speaking and Pronunciation</strong></h5>
        <ul>
          <li>Improving Pronunciation and Intonation in Arabic</li>
          <li>Practice in Conversational Arabic</li>
          <li>Role-Playing Scenarios for Daily Life and Work</li>
          <li>Public Speaking and Presentation Skills in Arabic</li>
        </ul>

        {/* Module 5 */}
        <h4>Module 5</h4>
        <h5><strong>Listening and Comprehension</strong></h5>
        <ul>
          <li>Strategies for Understanding Spoken Arabic</li>
          <li>Listening to Different Arabic Dialects</li>
          <li>Audio and Video Practice Materials</li>
          <li>Strategies for Understanding Spoken Arabic</li>
        </ul>

        {/* Module 6 */}
        <h4>Module 6</h4>
        <h5><strong>Reading and Writing Skills</strong></h5>
        <ul>
          <li>Reading Strategies for Arabic Texts (Articles, Books, etc.)</li>
          <li>Writing Skills for Essays, Emails, and Formal Documents</li>
          <li>Practice in Writing Clear and Coherent Arabic Texts</li>
          <li>Editing and Proofreading Techniques</li>
        </ul>

        {/* Module 7 */}
        <h4>Module 7</h4>
        <h5><strong>Arabic for Specific Purposes (ASP)</strong></h5>
        <ul>
          <li>Arabic for Business Communication</li>
          <li>Arabic for Academic Purposes</li>
          <li>Arabic for Travel and Hospitality</li>
          <li>Arabic for Customer Service</li>
        </ul>
      </Element>
    </>
  );
};

export default ArabicCourseDetails;
