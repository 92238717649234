import React, { useRef, useEffect } from 'react'

function FoodIndustryManagementandOperations({selectedSection}) {

    const courseOverviewRef = useRef(null);
    const syllabusRef = useRef(null);
    const durationRef = useRef(null);
    const sessionsRef = useRef(null);


    useEffect(() => {
        console.log("FoodIndustryManagementandOperations");
        if (selectedSection === 'courseOverview') 
            {
                courseOverviewRef.current?.scrollIntoView({ behavior: 'smooth' });
                
            }
        if (selectedSection === 'syllabus')
        {
            syllabusRef.current?.scrollIntoView({ behavior: 'smooth' });
            
        }
            
        if (selectedSection === 'duration') 
        {
            durationRef.current?.scrollIntoView({ behavior: 'smooth' });
            
        }
            
        if (selectedSection === 'sessions')
        {
            sessionsRef.current?.scrollIntoView({ behavior: 'smooth' });

        }

        // return () => {
        //     courseOverviewRef.current = null;
        //     syllabusRef.current = null;
        //     durationRef.current = null;
        //     sessionsRef.current = null;
        //   };
           
    }, [selectedSection]);



    return (
        <>
            <div ref={courseOverviewRef} className="mb-4">
                <h2>Food Industry Management and Operations</h2>
                <p> This course is designed to equip participants with the essential knowledge and skills to manage operations within the food industry. Covering everything from food safety and quality control to production management, marketing, and sustainability, the course prepares participants for leadership roles in various food-related sectors.
                </p>
            </div>

            <div ref={durationRef} className="mb-4">
                <h2>Course Duration</h2>
                <p><strong>Total Duration:</strong> 8 weeks</p>
            </div>

            <div ref={sessionsRef} className="mb-4">
                <h2>Sessions</h2>
                <p>3 days per week,</p>
                <p>3 hours per session</p>
            </div>
            <div ref={syllabusRef} className="mb-4">
                <h2>Syllabus</h2>
                <>
                    <h4>Module 1</h4>
                    <h5><strong>Introduction to the Food Industry</strong></h5>
                    <ul>
                        <li>Overview of the Global Food Industry</li>
                        <li>Key Sectors and Market Trends</li>
                        <li>The Role of Technology in the Food Industry</li>
                    
                    </ul>
                </>    
                
                 <>
                    <h4>Module 2</h4>
                    <h5><strong>Food Safety and Quality Management</strong></h5>
                    <ul>
                        <li>Understanding Food Safety Regulations and Standards</li>
                        <li>Hazard Analysis and Critical Control Points (HACCP)</li>
                        <li>Quality Control Processes in Food Production</li>
                        <li>Ensuring Compliance with Health and Safety Regulations</li>
                    </ul>
                </>


                <>
                    <h4>Module 3</h4>
                    <h5><strong>Food Production and Operations Management</strong></h5>
                    <ul>
                        <li>Supply Chain Management in the Food Industry</li>
                        <li>Production Planning and Process Optimization</li>
                        <li>Inventory Management and Cost Control</li>
                        <li>Lean Manufacturing Techniques in Food Production</li>
                    </ul>
                </>

                 <>
                    <h4>Module 4</h4>
                    <h5><strong>Marketing and Sales in the Food Industry</strong></h5>
                    <ul>
                        <li>Developing a Brand and Product Positioning</li>
                        <li>Marketing Strategies for Food Products</li>
                        <li>Understanding Consumer Behavior in Food Markets</li>
                        <li>Online and Offline Sales Channels</li>
                    </ul>
                </>
                
                <>
                    <h4>Module 5</h4>
                    <h5><strong>Sustainability in the Food Industry</strong></h5>
                    <ul>
                        <li>Sustainable Sourcing and Ethical Practices</li>
                        <li>Waste Management and Reduction Strategies</li>
                        <li>Energy Efficiency in Food Production</li>
                        <li>Implementing Green Practices in Operations</li>
                    </ul>
                </>
 
                 <>
                    <h4>Module 6</h4>
                    <h5><strong>Innovation and Technology in Food Industry</strong></h5>
                    <ul>
                        <li>The Role of Innovation in Product Development</li>
                        <li>Emerging Technologies in Food Production and Safety</li>
                        <li>Digital Transformation in the Food Industry</li>
                        <li>Case Studies of Innovative Food Companies</li>
                    </ul>
                </>
                 
                <>
                    <h4>Module 7</h4>
                    <h5><strong>Financial Management in the Food Industry</strong></h5>
                    <ul>
                        <li>Budgeting and Financial Planning for Food Businesses</li>
                        <li>Cost Control and Profitability Analysis</li>
                        <li>Pricing Strategies for Food Products</li>
                        <li>Financial Risk Management in Food Operations</li>
                    </ul>
                </>

                <>
                    <h4>Module 8</h4>
                    <h5><strong>Legal and Regulatory Considerations</strong></h5>
                    <ul>
                        <li>Understanding Food Industry Regulations and Compliance</li>
                        <li>Intellectual Property and Trademark Protection</li>
                        <li>Navigating Trade Laws and Export Regulations</li>
                        <li>Crisis Management and Food Safety Recalls</li>
                    </ul>
                </>

            </div>

        </>

    
  )
}

export default FoodIndustryManagementandOperations