import React from 'react';
import { Container, Row, Col, Card,Image } from 'react-bootstrap';
import './HospitalityIndustriesPage.css'; // Import custom CSS


const HospitalityGuidenceCareerPage = () => {
  return (
    <>
          {/* Full-Width Image Section */}
          <div className="full-width-image">
            <Image
              src="images/guidances.png" // Replace with your image URL
              alt="Hospitality Industry"
              className="img-fluid"
            />
          </div>
    
    <Container className="my-4">
      <h2 className="text-center mb-4 h2clas">Our Career Guidance Program</h2>
      <p>At Ocupiie Hospitality Academy, we are dedicated not only to providing
exceptional training but also to ensuring that our students are well-prepared to
pursue successful careers in the hospitality and related industries. Our Student
Career Guidance Program is designed to help students navigate their career paths,
offering personalized support and industry insights.


</p>
      <Row className="gy-4">
        {/* Section 1 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">1. Career Counselling and Development</Card.Title>
              <p>Personalized counselling sessions with experienced career advisors to help students identify their strengths, interests, and goals.</p>
              <p>Guidance on selecting the right career path within the hospitality industry, including roles in hotel management, front office operations, food & beverage management, and more.</p>
              <p>Assistance in creating a career development plan to map out long-term growth and success.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 2 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">2. Resume and Cover Letter Workshops</Card.Title>
              <p>Resume building workshops to help students create professional resumes that highlight their skills, training, and experience.</p>
              <p>Cover letter writing sessions to teach students how to craft personalized and impactful cover letters tailored to job applications.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 3 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">3. Job Search and Placement Support</Card.Title>
              <p>Access to Ocupiie’s extensive network of industry contacts and partners for job placement assistance.</p>
              <p>Job search guidance, including tips on finding the right job opportunities and applying effectively.</p>
              <p>Collaboration with leading hotels, resorts, restaurants, and other businesses to connect students with potential employers.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 4 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">4. Interview Preparation</Card.Title>
              <p>Mock interview sessions to build confidence and sharpen interview skills.</p>
              <p>Guidance on answering common interview questions, discussing salary expectations, and making a positive impression.</p>
              <p>Body language and communication skills training to ensure students present themselves professionally.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 5 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">5. Industry Insights and Trends</Card.Title>
              <p>Regular seminars and workshops with industry experts to provide insights into the latest trends, challenges, and opportunities in the hospitality sector.</p>
              <p>Updates on emerging job roles and the skills required to succeed in a rapidly evolving industry.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 6 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">6. Internship and On-the-Job Training Opportunities</Card.Title>
              <p>Assistance in securing internships and practical training placements at reputable hotels and hospitality establishments.</p>
              <p>Support in gaining real-world experience to enhance employability and build industry connections.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 7 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">7. Alumni Network and Mentorship</Card.Title>
              <p>Access to our global alumni network, offering mentorship and guidance from successful professionals in the hospitality industry.</p>
              <p>Opportunities to participate in alumni events and network with industry leaders and former students who have achieved success in their careers.</p>
            </Card.Body>
          </Card>
        </Col>


        {/* Section 8 */}
        <Col md={9}>
          <Card className="border-box">
            <Card.Body className='ulclas'>
              <Card.Title className="sub-heading-bg">8. Career Fairs and Recruitment Events</Card.Title>
              <p>Hosting regular career fairs where students can meet with recruiters from top hospitality brands and businesses.</p>
              <p>Opportunities to attend recruitment events organized by Ocupiie, ensuring direct access to potential employers.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};


export default HospitalityGuidenceCareerPage;




